<template>
	<section id="administrations" class="list-administrations">
		<b-row style="justify-content: space-between" class="mb-2 align-center">
			<b-col>
				<Breadcrumb :labels="labels" :labelsOnlyView="labelsOnlyView" :types="typeExperts" :tabTypes="tabTypes" />
			</b-col>
		</b-row>
		<b-row class="list-item">
			<b-col md="12">
				<b-tabs card class="caption-add table-custom" v-model="tabIndexSingle">
					<b-tab title="Basic Info" class="list-tab">
						<validation-observer ref="experts_ref" #default="{ invalid }">
							<b-form @submit.prevent class="profile-frm hasshadow"
								style="border-radius: 5px; margin-top: 10px">
								<div class="text-right mb-2">
									<template v-if="$route.name == 'staff-management-expert-detail'">

										<template v-if="(userData && permission.edit)">

											<b-button type="button" variant="primary" class="btn-df size-18"
												@click="toEdit(detail.id)">
												Edit
											</b-button>

										</template>
										<template v-else>
											<b-button type="button" variant="primary"
												class="btn-df size-18 btn-disabled-gray" disabled>
												Edit
											</b-button>
										</template>

									</template>
									<template v-else>
										<template v-if="(userData && permission.edit)">

											<!-- <b-button type="button" variant="primary" class="btn-df size-18 black" v-if="!isEdit" @click="editAction">
													Edit
							                    </b-button> -->
											<b-button type="button" variant="basic" class="btn-df size-18 black"
												@click="cancelEdit"
												style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
											<b-button type="button" variant="primary" class="btn-df size-18 black"
												@click="saveEdit()">
												Save
											</b-button>
										</template>
										<template v-else>
											<b-button type="button" variant="primary"
												class="btn-df size-18 btn-disabled-gray" disabled>
												Edit
											</b-button>
										</template>
									</template>
								</div>
								<div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
								<div class="frm-basic size-16 d-block">
									<b-row style="width: 100%; margin: 0;">
										<b-col lg="6" class="px-0">
											<b-form-group label="Date of Submission" label-cols-lg="4"
												class="star-required">
												<validation-provider #default="{ errors }" name="Date of Submission"
													rules="required">
													<flat-pickr class="form-control" v-model="step1.date_form"
														:config="{ altInput: true, altFormat: 'd/m/y', dateFormat: 'm-d-Y' }"
														placeholder="Select a date" :disabled="true"></flat-pickr>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="Status" label-cols-lg="4" class="star-required">
												<v-select v-model="selectedStatus" :options="optionsStatus" label="text"
													placeholder="Selected Status" :disabled="isDisabled"></v-select>
											</b-form-group>
										</b-col>
										<template v-if="is_show">
											<b-col lg="12" class="px-0">
												<b-form-group label="Username" label-cols-lg="2" class="input-group-label">
													<b-form-input v-model="expertUsername" placeholder="Username"
														:formatter="length50" :disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="Password" label-cols-lg="4"
													label-class="text-lg-right bold" class="input-group-label">
													<validation-provider #default="{ errors }" rules="min:12"
														name="password" vid="password">
														<b-form-input placeholder="Password"
															v-model="editStaffExpert.password" type="password"
															:disabled="isDisabled"></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>

													</validation-provider>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="Re-type Password" label-cols-lg="4"
													label-class="text-lg-right bold" class="input-group-label">
													<validation-provider #default="{ errors }" rules="confirmed:password"
														name="Re-type Password">
														<b-form-input placeholder="Re-type Password"
															v-model="editStaffExpert.confirmPassword" type="password"
															:disabled="isDisabled"></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
										</template>
									</b-row>
									<div style="border-bottom: 1px solid #B1B1B1; padding-bottom: 7px" class="mb-2"></div>
									<b-row style="width: 100%; margin: 0;">
										<b-col lg="12" class="px-0">
											<p style="color: #8471FF" class="bold">A. Travel Preference</p>
											<p style="color: #29D3B5" class="bold">1. Indicate countries which you are not
												comfortable to travel to (if any).</p>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="1." label-cols-lg="4" class="input-group-label">
												<b-form-input v-model="step1.countries_name_1" placeholder="Country"
													:formatter="length75" :disabled="isDisabled"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="2." label-cols-lg="4" class="input-group-label">
												<b-form-input v-model="step1.countries_name_2" placeholder="Country"
													:formatter="length75" :disabled="isDisabled"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="3." label-cols-lg="4" class="input-group-label">
												<b-form-input v-model="step1.countries_name_3" placeholder="Country"
													:formatter="length75" :disabled="isDisabled"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="4." label-cols-lg="4" class="input-group-label">
												<b-form-input v-model="step1.countries_name_4" placeholder="Country"
													:formatter="length75" :disabled="isDisabled"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="5." label-cols-lg="4" class="input-group-label">
												<b-form-input v-model="step1.countries_name_5" placeholder="Country"
													:formatter="length75" :disabled="isDisabled"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="12" class="px-0">
											<p style="color: #29D3B5" class="bold">2. Willing to Travel Overseas for more
												than 1 week.</p>
										</b-col>
										<b-col lg="6" class="px-0 cb-aligncenter">
											<b-form-group label="Answer" label-cols-lg="4" class="star-required">
												<validation-provider #default="{ errors }" name="Answer" rules="required">
													<b-form-radio-group label="title" v-model="selectedAnswer"
														:options="optionsAnswer" :disabled="isDisabled">
													</b-form-radio-group>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<template v-if="selectedAnswer == 'Yes (Proceed to Question 3)'">
											<b-col lg="12" class="px-0">
												<b-form-group
													label="3. Indicate your preferred length of travel if asked to undertake any Overseas Assignment."
													label-cols-lg="2" class="star-required">
													<validation-provider #default="{ errors }" name="This" rules="required">
														<b-form-textarea v-model="step1.text_travel_length"
															placeholder="Description" rows="5" :formatter="length1000"
															:disabled="isDisabled"></b-form-textarea>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
										</template>

										<b-col lg="12" class="px-0">
											<div style="border-bottom: 1px solid #B1B1B1; padding-bottom: 7px" class="mb-2">
											</div>
										</b-col>
										<b-col lg="12" class="px-0">
											<p style="color: #8471FF" class="bold">B. Personal Particulars</p>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="1. Family Name" label-cols-lg="4"
												class="star-required input-group-label">
												<validation-provider #default="{ errors }" name="Family Name"
													rules="required">
													<b-form-input v-model="step1.text_personal_family"
														placeholder="Family Name" :formatter="length75"
														:disabled="isDisabled"></b-form-input>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="2. Given Name" label-cols-lg="4"
												class="star-required input-group-label">
												<validation-provider #default="{ errors }" name="Given Name"
													rules="required">
													<b-form-input v-model="step1.text_personal_given"
														placeholder="Given Name" :formatter="length75"
														:disabled="isDisabled"></b-form-input>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="3. Nationality" label-cols-lg="4" class="star-required">
												<validation-provider #default="{ errors }" name="Nationality"
													rules="required">
													<v-select v-model="selectedNationlity" :options="optionsNationlity"
														label="text" placeholder="Nationality"
														:disabled="isDisabled"></v-select>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="4. Race" label-cols-lg="4" class="star-required">
												<validation-provider #default="{ errors }" name="Race" rules="required">
													<v-select v-model="selectedRace" :options="optionsRace" label="text"
														placeholder="Race" :disabled="isDisabled"></v-select>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="5. Religion" label-cols-lg="4" class="star-required">
												<validation-provider #default="{ errors }" name="Religion" rules="required">
													<v-select v-model="selectedReligion" :options="optionsReligion"
														label="text" placeholder="Religion"
														:disabled="isDisabled"></v-select>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="6. Marital Status" label-cols-lg="4" class="star-required">
												<validation-provider #default="{ errors }" name="Marital Status"
													rules="required">
													<v-select v-model="selectMarital" :options="optionsMarital" label="text"
														placeholder="Status" :disabled="isDisabled"></v-select>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="7. Date of Birth" label-cols-lg="4" class="star-required">
												<flat-pickr v-model="step1.date_personal_date" class="form-control"
													:config="{ altInput: true, altFormat: 'd/m/Y', dateFormat: 'm-d-Y' }"
													placeholder="Select a date" :disabled="isDisabled"
													required></flat-pickr>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="9. Contact Number" label-cols-lg="4"
												class="star-required input-group-label">
												<validation-provider #default="{ errors }" name="Contact Number"
													rules="required">
													<b-form-input v-model="step1.text_personal_contact"
														placeholder="Contact No." :formatter="length20" type="number"
														:disabled="isDisabled"></b-form-input>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<b-col lg="12" class="px-0">
											<b-form-group label="8. Email" label-cols-lg="2"
												class="star-required input-group-label">
												<validation-provider #default="{ errors }" name="Email"
													rules="required|email">
													<b-form-input v-model="step1.email_personal_email" type="email"
														placeholder="Email" :disabled="isDisabled"></b-form-input>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<b-col lg="12" class="px-0">
											<div style="border-bottom: 1px solid #B1B1B1; padding-bottom: 7px" class="mb-2">
											</div>
										</b-col>
										<b-col lg="12" class="px-0">
											<p style="color: #8471FF" class="bold">C. Proficiency</p>
											<p style="color: #29D3B5" class="bold mb-2">Computer Proficiency <span
													style="color: #E4656C">*</span></p>
										</b-col>
										<b-col lg="12" class="px-0 cb-aligncenter cb-w-25">
											<b-form-group>
												<b-form-checkbox-group v-model="step1.checkbox_proficiencies_1"
													:options="optionsProfi1" name="checkbox-proficiencies_1"
													:disabled="isDisabled">
												</b-form-checkbox-group>
											</b-form-group>
											<b-form-group label="Others (if any)" label-cols-lg="2"
												class="input-group-label">
												<b-form-input v-model="step1.text_proficiencies_other_1"
													placeholder="Optional" :formatter="length75"
													:disabled="isDisabled"></b-form-input>
											</b-form-group>
											<template
												v-if="(step1.checkbox_proficiencies_1 && step1.checkbox_proficiencies_1.length == 0 && !step1.text_proficiencies_other_1) || (step1.checkbox_proficiencies_1 && step1.checkbox_proficiencies_1[0] == 'Others (Please specify):' && !step1.text_proficiencies_other_1 && step1.checkbox_proficiencies_1.length == 1)">
												<span class="text-danger">
													This field is required
												</span>
											</template>
											<!-- show require if one -->
											<div style="border-bottom: 1px solid #B1B1B1; padding-bottom: 7px" class="mb-2">
											</div>
										</b-col>
										<b-col lg="12" class="px-0 cb-aligncenter cb-w-25">
											<p style="color: #29D3B5" class="bold mb-2">2. Language Proficiency <span
													style="color: #E4656C">*</span></p>
											<b-form-group>
												<b-form-checkbox-group v-model="step1.checkbox_proficiencies_2"
													:options="optionsProfi2" name="checkbox-proficiencies_1"
													:disabled="isDisabled">
												</b-form-checkbox-group>
											</b-form-group>
											<b-form-group label="Others (if any)" label-cols-lg="2"
												class="input-group-label">
												<b-form-input v-model="step1.text_proficiencies_other_2"
													placeholder="Optional" :formatter="length75"
													:disabled="isDisabled"></b-form-input>
											</b-form-group>
											<template
												v-if="(step1.checkbox_proficiencies_2 && step1.checkbox_proficiencies_2.length == 0 && !step1.text_proficiencies_other_2) || (step1.checkbox_proficiencies_2 && step1.checkbox_proficiencies_2[0] == 'Others (Please specify):' && !step1.text_proficiencies_other_2 && step1.checkbox_proficiencies_2.length == 1)">
												<span class="text-danger">
													This field is required
												</span>
											</template>
											<!-- show arrow if one -->
											<div style="border-bottom: 1px solid #B1B1B1; padding-bottom: 7px" class="mb-2">
											</div>
										</b-col>

										<template v-if="detail.eoi_type == 0">
											<b-col lg="12" class="px-0">
												<p style="color: #8471FF" class="bold">D. Highest Academic Qualification
													<span style="color: #E4656C">*</span></p>
											</b-col>
											<b-col lg="12" class="pl-5t px-0">
												<b-form-group label="1. School / Institution" label-cols-lg="2"
													class="input-group-label">
													<validation-provider #default="{ errors }" name="School / Institution"
														rules="required">
														<b-form-input v-model="step1.text_qua_name"
															placeholder="Name of school/Institution" :formatter="length75"
															:disabled="isDisabled"></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<b-form-group label="2. Course Name" label-cols-lg="2"
													class="input-group-label">
													<validation-provider #default="{ errors }" name="Course Name"
														rules="required">
														<b-form-input v-model="step1.text_qua_course"
															placeholder="Course name" :formatter="length75"
															:disabled="isDisabled"></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="3. Country of Study" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_qua_country" placeholder="Country"
														:formatter="length75" :disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="4. Graduation Year" label-cols-lg="4"
													class="input-group-label">
													<validation-provider #default="{ errors }" name="Graduation Year"
														rules="required">
														<b-form-input v-model="step1.text_qua_graduation" type="number"
															placeholder="YYYY" :disabled="isDisabled" />
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<b-form-group label="5. Qualification Attained" label-cols-lg="2"
													class="input-group-label">
													<validation-provider #default="{ errors }" name="Qualification Attained"
														rules="required">
														<b-form-input v-model="step1.text_qua_attained"
															placeholder="Highest Qaulification Attained"
															:formatter="length75" :disabled="isDisabled"></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<div style="border-bottom: 1px solid #B1B1B1; padding-bottom: 7px"
													class="mb-2"></div>
												<p style="color: #8471FF" class="bold">E. TVET Profession and/or Industry
													Experience</p>
												<p style="color: #29D3B5" class="bold mb-2">Current Profession(1)</p>
											</b-col>
											<b-col lg="12" class="pl-5t px-0">
												<b-form-group label="1. Organisation Name" label-cols-lg="2"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_name_1"
														placeholder="Organisation Name" :formatter="length75"
														:disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="2. Type of Industry" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_industry_1"
														placeholder="Type of Industry" :formatter="length75"
														:disabled="isDisabled" />
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0 pl-5t">
												<b-form-group label="3. Nature Of Business" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_business_1"
														placeholder="Nature of business" :formatter="length75"
														:disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="4. Length of Service" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_service_1"
														placeholder="No. of years" :formatter="length20" type="number"
														:disabled="isDisabled"></b-form-input>
													<!-- type: number -->
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="5. Occupation/Job" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_job_1"
														placeholder="Role Name" :formatter="length75"
														:disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<b-form-group label="6. Key Job Scope and Responsibility" label-cols-lg="2">
													<b-form-textarea v-model="step1.text_profession_scope_1"
														placeholder="Job scope and responsibility" rows="5"
														:formatter="length100" :disabled="isDisabled"></b-form-textarea>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="7. Key Area of Expertise" label-cols-lg="4">
													<b-button type="button" variant="primary" class="btn-df size-18 black"
														style="background-color: #8471FF !important; border-color: #8471FF !important; max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) "
														@click="ACurrentModal">
														View List Area of Expertise (Current)
													</b-button>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0"></b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="8. Referee Name" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_referee_name_1"
														placeholder="Name" :formatter="length75"
														:disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="Contact Info." label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_referee_contact_1"
														placeholder="Contact No. /Email" :formatter="length75"
														:disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<p style="color: #29D3B5" class="bold mb-2">Past Profession(2)</p>
											</b-col>
											<b-col lg="12" class="pl-5t px-0">
												<b-form-group label="1. Organisation Name" label-cols-lg="2"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_name_2"
														placeholder="Organisation Name" :formatter="length75"
														:disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="2. Type of Industry" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_industry_2"
														placeholder="Type of Industry" :formatter="length75"
														:disabled="isDisabled">
													</b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0 pl-5t">
												<b-form-group label="3. Nature Of Business" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_business_2"
														placeholder="Nature of business" :formatter="length75"
														:disabled="isDisabled">
													</b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="4. Length of Service" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_service_2"
														placeholder="No. of years" :formatter="length20" type="number"
														:disabled="isDisabled">
													</b-form-input>
													<!-- number -->
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="5. Occupation/Job" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_job_2"
														placeholder="Role Name" :formatter="length75"
														:disabled="isDisabled">
													</b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<b-form-group label="6. Key Job Scope and Responsibility" label-cols-lg="2">
													<b-form-textarea v-model="step1.text_profession_scope_2"
														placeholder="Job scope and responsibility" rows="5"
														:formatter="length100" :disabled="isDisabled"></b-form-textarea>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="7. Key Area of Expertise" label-cols-lg="4">
													<b-button type="button" variant="primary" class="btn-df size-18 black"
														style="background-color: #8471FF !important; border-color: #8471FF !important; max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) "
														@click="APassModal">
														View List Area of Expertise (Past)
													</b-button>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0"></b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="8. Referee Name" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_referee_name_2"
														placeholder="Name" :formatter="length75" :disabled="isDisabled">
													</b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="Contact Info." label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_profession_referee_contact_2"
														placeholder="Contact No. /Email" :formatter="length75"
														:disabled="isDisabled">
													</b-form-input>
												</b-form-group>
											</b-col>
										</template>
										<template v-else-if="detail.eoi_type == 1">
											<b-col lg="12" class="px-0">
												<p style="color: #8471FF" class="bold">D. Current Profession Status <span
														style="color: #E4656C">*</span></p>
											</b-col>
											<b-col lg="6" class="px-0 cb-aligncenter">
												<b-form-group label="Current Status" label-cols-lg="4"
													class="star-required radio-block">
													<validation-provider #default="{ errors }" name="Current Status"
														rules="required">
														<b-form-radio-group label="title" v-model="selectedCurStatus"
															:options="optionsCurStatus" :disabled="isDisabled">
														</b-form-radio-group>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="Last Date of employment" label-cols-lg="4">
													<flat-pickr class="form-control" v-model="step1.text_current_status_1"
														:config="{ altInput: true, altFormat: 'd/m/y', dateFormat: 'm-d-Y' }"
														placeholder="Select a date"
														:disabled="isDisabled ? isDisabled : disabled_CurSatatus_1"></flat-pickr>
												</b-form-group>
												<b-form-group label="Year of Retirement" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_current_status_2" type="number"
														placeholder="Year of Retirement"
														:disabled="isDisabled ? isDisabled : disabled_CurSatatus_2"></b-form-input>
												</b-form-group>
											</b-col>
											<!--  -->
											<b-col lg="12" class="px-0">
												<div style="border-bottom: 1px solid #B1B1B1; padding-bottom: 7px"
													class="mb-2"></div>
												<p style="color: #8471FF" class="bold">E. TVET Profession and/or Industry
													Experience</p>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="Referee Name" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_e_referee_name_1" placeholder="Name"
														:formatter="length75" :disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="Contact Info." label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_e_referee_contact_2"
														placeholder="Contact No. /Email" :formatter="length75"
														:disabled="isDisabled">
													</b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<p style="color: #29D3B5" class="bold mb-2">Current Profession(1)</p>
											</b-col>
											<b-col lg="6" class="px-0 pl-5t">
												<b-form-group label="1. Designation" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_e_designation_1"
														placeholder="Designation" :formatter="length75"
														:disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="2. Length of Service" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_e_length_1" placeholder="No. of years"
														:formatter="length20" type="number"
														:disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<b-form-group label="3. Key Job Scope and Responsibility" label-cols-lg="2">
													<b-form-textarea v-model="step1.text_e_key_1"
														placeholder="Job scope and responsibility" rows="5"
														:formatter="length100" :disabled="isDisabled"></b-form-textarea>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="4. Key Area of Expertise" label-cols-lg="4">
													<b-button type="button" variant="primary" class="btn-df size-18 black"
														style="background-color: #8471FF !important; border-color: #8471FF !important; max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) "
														@click="ACurrentModal">
														View List Area of Expertise (Current)
													</b-button>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0"></b-col>
											<b-col lg="12" class="px-0">
												<p style="color: #29D3B5" class="bold mb-2">Past Profession(2)</p>
											</b-col>
											<b-col lg="6" class="px-0 pl-5t">
												<b-form-group label="1. Designation" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_e_designation_2"
														placeholder="Designation" :formatter="length75"
														:disabled="isDisabled">
													</b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="2. Length of Service" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_e_length_2" placeholder="No. of years"
														:formatter="length20" type="number" :disabled="isDisabled">
													</b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<b-form-group label="3. Key Job Scope and Responsibility" label-cols-lg="2">
													<b-form-textarea v-model="step1.text_e_key_2"
														placeholder="Job scope and responsibility" rows="5"
														:formatter="length100" :disabled="isDisabled"></b-form-textarea>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="4. Key Area of Expertise" label-cols-lg="4">
													<b-button type="button" variant="primary" class="btn-df size-18 black"
														style="background-color: #8471FF !important; border-color: #8471FF !important; max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) "
														@click="APassModal">
														View List Area of Expertise (Past)
													</b-button>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0"></b-col>
										</template>
										<template v-else>
											<b-col lg="12" class="px-0">
												<p style="color: #8471FF" class="bold">D. Current Profession Status <span
														style="color: #E4656C">*</span></p>
											</b-col>
											<b-col lg="6" class="px-0 cb-aligncenter radio-pr-5">
												<b-form-group label="Current ITE Staff" label-cols-lg="4"
													class="star-required">
													<validation-provider #default="{ errors }" name="Current Status"
														rules="required">
														<b-form-radio-group label="title" v-model="selectedIteCurStatus"
															:options="optionsIteCurStatus" class="pl-3"
															:disabled="isDisabled">
														</b-form-radio-group>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="Year of Employment" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_status"
														placeholder="Year of Employment"
														:disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<!--  -->
											<b-col lg="12" class="px-0">
												<div style="border-bottom: 1px solid #B1B1B1; padding-bottom: 7px"
													class="mb-2"></div>
												<p style="color: #8471FF" class="bold">E. TVET Profession and/or Industry
													Experience</p>
											</b-col>
											<b-col lg="6" class="px-0 pl-5t">
												<b-form-group label="1. Designation" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_e_designation"
														placeholder="Designation" :formatter="length75"
														:disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="2. Length of Service" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_e_length" placeholder="No. of years"
														:formatter="length20" type="number"
														:disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<b-form-group label="3. Key Job Scope and Responsibility" label-cols-lg="2">
													<b-form-textarea v-model="step1.text_e_key"
														placeholder="Job scope and responsibility" rows="5"
														:formatter="length100" :disabled="isDisabled"></b-form-textarea>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="4." label-cols-lg="4">
													<b-button type="button" variant="primary" class="btn-df size-18 black"
														style="background-color: #8471FF !important; border-color: #8471FF !important; max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) "
														@click="ACurrentModal">
														View List Area of Expertise (Current)
													</b-button>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="" label-cols-lg="4">
													<b-button type="button" variant="primary" class="btn-df size-18 black"
														style="background-color: #8471FF !important; border-color: #8471FF !important; max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) "
														@click="APassModal">
														View List Area of Expertise (Past)
													</b-button>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="5. Referee Name" label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_e_referee_name_1" placeholder="Name"
														:formatter="length75" :disabled="isDisabled"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="Contact Info." label-cols-lg="4"
													class="input-group-label">
													<b-form-input v-model="step1.text_e_referee_contact_2"
														placeholder="Contact No. /Email" :formatter="length75"
														:disabled="isDisabled">
													</b-form-input>
												</b-form-group>
											</b-col>
										</template>

										<b-col lg="12" class="px-0">
											<div style="border-bottom: 1px solid #B1B1B1; padding-bottom: 7px" class="mb-2">
											</div>
											<p style="color: #8471FF" class="bold mb-2">F. 1 Experience with ITEES - Part 1
												<span style="color: #E4656C">*</span></p>
										</b-col>
										<b-col lg="6" class="px-0 cb-aligncenter pl-5t radio-w-50">
											<b-form-group
												label="1. Is this your first time undertaking ITEES' assignment(s)?"
												label-cols-lg="4" class="mb-0">
												<validation-provider #default="{ errors }" name="This" rules="required">
													<b-form-radio-group label="text" v-model="selectedExperience_1"
														:options="optionsExperience_1" :disabled="isDisabled">
													</b-form-radio-group>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0 height-child-100">
											<b-form-group label="No. of assignments" label-cols-lg="4"
												class="mb-0 input-group-label">
												<b-form-input v-model="step1.text_experience_other"
													placeholder="No. of assignments" :formatter="length20" type="number"
													:disabled="isDisabled"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="12" class="px-0 pb-1"></b-col>
										<b-col lg="6" class="px-0 cb-aligncenter radio-w-50">
											<b-form-group label="2. Are you undertaking any ITEES' assigment(s) currently?"
												label-cols-lg="4">
												<validation-provider #default="{ errors }" name="This" rules="required">
													<b-form-radio-group label="text" v-model="selectedExperience_2"
														:options="optionsExperience_2" :disabled="isDisabled">
													</b-form-radio-group>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>
										<b-col lg="12" class="px-0">
											<div style="border-bottom: 1px solid #B1B1B1; padding-bottom: 7px" class="mb-2">
											</div>
											<p style="color: #8471FF" class="bold mb-2">F.2 Experience with ITEES - Part 2
											</p>
										</b-col>
										<b-col lg="12" class="pl-5t px-0">
											<b-form-group label="1. Project Title" label-cols-lg="2"
												class="input-group-label">
												<b-form-input v-model="step1.text_experience_title" placeholder="Title"
													:formatter="length75" :disabled="isDisabled"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="2. Project Activity" label-cols-lg="4">
												<b-button type="button" variant="primary" class="btn-df size-18 black"
													style="background-color: #8471FF !important; border-color: #8471FF !important; max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) "
													@click="proActivityModal">
													View List
												</b-button>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0"></b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="3A. Year of Appointment" label-cols-lg="4"
												class="input-group-label">
												<b-form-input v-model="step1.text_experience_yeah_appointment_1"
													placeholder="Appointment 1" type="number"
													:disabled="isDisabled"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="3B. Year of Appointment" label-cols-lg="4"
												class="input-group-label">
												<b-form-input v-model="step1.text_experience_yeah_appointment_2"
													type="number" placeholder="Appointment 2"
													:disabled="isDisabled"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="4A. Job Role" label-cols-lg="4" class="input-group-label">
												<b-form-input v-model="step1.text_experience_job_role_1"
													placeholder="Role 1" :formatter="length75"
													:disabled="isDisabled"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="4B. Job Role" label-cols-lg="4" class="input-group-label">
												<b-form-input v-model="step1.text_experience_job_role_2"
													placeholder="Role 2" :formatter="length75"
													:disabled="isDisabled"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="5A. Key Job Scope and Responsibility" label-cols-lg="4">
												<b-form-textarea v-model="step1.text_experience_key_respon_1" rows="5"
													placeholder="Job scope and responsibility 1" :formatter="length100"
													:disabled="isDisabled"></b-form-textarea>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="5B. Key Job Scope and Responsibility" label-cols-lg="4">
												<b-form-textarea v-model="step1.text_experience_key_respon_2" rows="5"
													placeholder="Job scope and responsibility 2" :formatter="length100"
													:disabled="isDisabled"></b-form-textarea>
											</b-form-group>
										</b-col>
										<b-col lg="12" class="px-0">
											<div style="border-bottom: 1px solid #B1B1B1; padding-bottom: 7px" class="mb-2">
											</div>
											<p style="color: #8471FF" class="bold">G. Area(s) of Interests</p>
										</b-col>
										<b-col lg="6" class="px-0">
											<b-form-group label="1. Activity" label-cols-lg="4">
												<b-button type="button" variant="primary" class="btn-df size-18 black"
													style="background-color: #8471FF !important; border-color: #8471FF !important; max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) "
													@click="ActivityModal">
													View List
												</b-button>
											</b-form-group>
										</b-col>
										<b-col lg="6" class="px-0"></b-col>
										<b-col lg="12" class="px-0">
											<b-form-group label="2. Other remarks" label-cols-lg="2">
												<b-form-textarea v-model="step1.text_interests_other_remarks" rows="5"
													placeholder="Remarks" :formatter="length1000"
													:disabled="isDisabled"></b-form-textarea>
											</b-form-group>
										</b-col>
									</b-row>
								</div>
							</b-form>
						</validation-observer>
						<b-modal id="modalACurrent" ref="ACurrent_ref" title="" hide-header ok-title="Save" centered>
							<div class="table-custom pt-1">
								<h4 class="bold mb-2">Annex A (Current)</h4>
								<template v-if="step2.cb_applied_health && step2.cb_applied_health[0] != null">
									<div class="decoration-hover pb-1">1. Applied and Health Sciences</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_applied_health">
											{{ item }}
										</li>
									</ul>
								</template>
								<template v-if="step2.cb_engineering && step2.cb_engineering[0] != null">
									<div class="decoration-hover pb-1">2. Engineering</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_engineering">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step2.cb_tech_electronics && step2.cb_tech_electronics[0] != null">
									<div class="decoration-hover pb-1">3. Info-Comm Technology and Electronics</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_tech_electronics">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step2.cb_business_services && step2.cb_business_services[0] != null">
									<div class="decoration-hover pb-1">4. Business and Services</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_business_services">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step2.cb_design_media && step2.cb_design_media[0] != null">
									<div class="decoration-hover pb-1">5. Design and Media</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_design_media">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step2.cb_hospitality && step2.cb_hospitality[0] != null">
									<div class="decoration-hover pb-1">6. Hospitality</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_hospitality">{{ item }}</li>
									</ul>
								</template>

								<template v-if="step2.cb_engineering && step2.cb_engineering[0] != null">
									<div class="decoration-hover pb-1">6. Hospitality</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_engineering">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step2.cb_life_skill && step2.cb_life_skill[0] != null">
									<div class="decoration-hover pb-1">7. Life Skills</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_life_skill">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step2.cb_quality && step2.cb_quality[0] != null">
									<div class="decoration-hover pb-1">8. Quality Assurance - TVET Excellence</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_quality">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step2.cb_train && step2.cb_train[0] != null">
									<div class="decoration-hover pb-1">9. Train-The-Trainer (TTT) Programmes</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_train">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step2.cb_pedadogical && step2.cb_pedadogical[0] != null">
									<div class="decoration-hover pb-1">10. Pedagogical Approaches</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_pedadogical">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step2.cb_leader && step2.cb_leader[0] != null">
									<div class="decoration-hover pb-1">11. TVET Leadership</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_leader">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step2.cb_tech && step2.cb_tech[0] != null">
									<div class="decoration-hover pb-1">12. Emerging Technology</div>
									<ul class="tranning">
										<li v-for="(item, index) in step2.cb_tech">{{ item }}</li>
									</ul>
								</template>
								<!-- text other - alway show -->
								<div class="decoration-hover pb-1">13. Others (Please specify here, if none of the above)
								</div>
								<b-form-textarea v-model="step2.textarea_other" rows="5" placeholder="Remarks"
									:formatter="length1000" />
							</div>
							<template #modal-footer="{ cancel }">
								<b-button size="sm" variant="static" class="btn-default mt-1 mb-2" @click="cancel()">
									Close
								</b-button>
							</template>
						</b-modal>
						<b-modal id="modalAPass" ref="APass_ref" title="" hide-header ok-title="Save" centered>
							<div class="table-custom pt-1">
								<h4 class="bold mb-2">Annex A (Pass)</h4>

								<template v-if="step3.cbp_applied_health && step3.cbp_applied_health[0] != null">
									<div class="decoration-hover pb-1">1. Applied and Health Sciences</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_applied_health">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step3.cbp_engineering && step3.cbp_engineering[0] != null">
									<div class="decoration-hover pb-1">2. Engineering</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_engineering">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step3.cbp_tech_electronics && step3.cbp_tech_electronics[0] != null">
									<div class="decoration-hover pb-1">3. Info-Comm Technology and Electronics</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_tech_electronics">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step3.cbp_business_services && step3.cbp_business_services[0] != null">
									<div class="decoration-hover pb-1">4. Business and Services</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_business_services">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step3.cbp_design_media && step3.cbp_design_media[0] != null">
									<div class="decoration-hover pb-1">5. Design and Media</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_design_media">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step3.cbp_hospitality && step3.cbp_hospitality[0] != null">
									<div class="decoration-hover pb-1">6. Hospitality</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_hospitality">{{ item }}</li>
									</ul>
								</template>

								<template v-if="step3.cbp_engineering && step3.cbp_engineering[0] != null">
									<div class="decoration-hover pb-1">6. Hospitality</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_engineering">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step3.cbp_life_skill && step3.cbp_life_skill[0] != null">
									<div class="decoration-hover pb-1">7. Life Skills</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_life_skill">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step3.cbp_quality && step3.cbp_quality[0] != null">
									<div class="decoration-hover pb-1">8. Quality Assurance - TVET Excellence</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_quality">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step3.cbp_train && step3.cbp_train[0] != null">
									<div class="decoration-hover pb-1">9. Train-The-Trainer (TTT) Programmes</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_train">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step3.cbp_pedadogical && step3.cbp_pedadogical[0] != null">
									<div class="decoration-hover pb-1">10. Pedagogical Approaches</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_pedadogical">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step3.cbp_leader && step3.cbp_leader[0] != null">
									<div class="decoration-hover pb-1">11. TVET Leadership</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_leader">{{ item }}</li>
									</ul>
								</template>
								<template v-if="step3.cbp_tech && step3.cbp_tech[0] != null">
									<div class="decoration-hover pb-1">12. Emerging Technology</div>
									<ul class="tranning">
										<li v-for="(item, index) in step3.cbp_tech">{{ item }}</li>
									</ul>
								</template>
								<!-- text other - alway show -->
								<div class="decoration-hover pb-1">13. Others (Please specify here, if none of the above)
								</div>
								<b-form-textarea v-model="step3.textarea_other" rows="5" placeholder="Remarks"
									:formatter="length1000" />
							</div>
							<template #modal-footer="{ cancel }">
								<b-button size="sm" variant="static" class="btn-default mt-1 mb-2" @click="cancel()">
									Close
								</b-button>
							</template>
						</b-modal>
						<b-modal id="modalProActivity" ref="ProActivity_ref" title="Project Activity" hide-header
							ok-title="Save" centered>
							<div class="table-custom pt-1">
								<h4 class="bold mb-2">Project Activity</h4>
								<div class="decoration-hover pb-1">Training</div>
								<template v-if="step1.cb_experience_training && step1.cb_experience_training[0] != null">
									<ul class="tranning">
										<li v-for="(item, index) in step1.cb_experience_training">{{ item }}</li>
									</ul>
								</template>
								<template v-else>
									<p style="color: gray">No data</p>
								</template>

								<div class="decoration-hover pb-1">Consultancy</div>
								<template
									v-if="step1.cb_experience_consultancy && step1.cb_experience_consultancy[0] != null">
									<ul class="tranning">
										<li v-for="(item, index) in step1.cb_experience_consultancy">{{ item }}</li>
									</ul>
								</template>
								<template v-else>
									<p style="color: gray">No data</p>
								</template>
							</div>
							<template #modal-footer="{ cancel }">
								<b-button size="sm" variant="static" class="btn-default" @click="cancel()">
									Close
								</b-button>
							</template>
						</b-modal>
						<b-modal id="ActivityModal" ref="Activity_ref" title="Project Activity" hide-header ok-title="Save"
							centered>
							<div class="table-custom pt-1">
								<h4 class="bold mb-2">Activity</h4>
								<div class="decoration-hover pb-1">Training</div>
								<template v-if="step1.cb_interests_training && step1.cb_interests_training[0] != null">
									<ul class="tranning">
										<li v-for="(item, index) in step1.cb_interests_training">{{ item }}</li>
									</ul>
								</template>
								<template v-else>
									<p style="color: gray">No data</p>
								</template>

								<div class="decoration-hover pb-1">Consultancy</div>
								<template
									v-if="step1.cb_interests_consultancy && step1.cb_interests_consultancy[0] != null">
									<ul class="tranning">
										<li v-for="(item, index) in step1.cb_interests_consultancy">{{ item }}</li>
									</ul>
								</template>
								<template v-else>
									<p style="color: gray">No data</p>
								</template>
							</div>
							<template #modal-footer="{ cancel }">
								<b-button size="sm" variant="static" class="btn-default" @click="cancel()">
									Close
								</b-button>
							</template>
						</b-modal>
					</b-tab>
					<b-tab title="ANNEX A" class="list-tab">
						<AnnexA  :permission="permission"/>
					</b-tab>
					<template v-if="hideAnnexB == false">
						<b-tab title="ANNEX B" class="list-tab">
							<AnnexB :permission="permission" />
						</b-tab>
					</template>
					<b-tab title="Documents" class="list-tab">
						<Document :permission="permission" />
					</b-tab>
					<b-tab title="Activity Assigned" class="list-tab tab-pt-0">
						<ActivityAssign />
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import Breadcrumb from '../../partial/Breadcrumb'
import flatPickr from 'vue-flatpickr-component'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AnnexA from './annexA'
import AnnexB from './annexB'
import Document from './document/List'
import ActivityAssign from './activity/List'
import globalAdmin from '../../model/globalAdmin'

export default {
	mixins: [globalAdmin],
	components: {
		Breadcrumb,
		flatPickr,
		Datepicker,
		vSelect,
		ValidationProvider,
		ValidationObserver,

		AnnexA,
		AnnexB,
		Document,
		ActivityAssign
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			permission: [],
			tabIndexSingle: 0,
			step2_cb_applied_health: null,
			labels: { "staff-management-expert-edit": "" },
			labelsOnlyView: { "staff-management-expert-detail": "TVET Expert" },
			typeExperts: { "staff-management-expert-edit": "" },
			tabTypes: { "staff-management-expert-edit": "" },

			isDisabled: true,
			isEdit: false,
			editing: false,
			disable_editActive: false,

			valueStatus: null,
			selectedAnswer: null,
			optionsAnswer: [
				{ text: 'Yes', value: 'Yes (Proceed to Question 3)' },
				{ text: 'No', value: 'No (Proceed to Section B' },
			],

			optionsProfi1: [
				{ text: 'Microsoft Word', value: 'Microsoft Word' },
				{ text: 'Microsoft Excel', value: 'Microsoft Excel' },
				{ text: 'Microsoft Powerpoint', value: 'Microsoft Powerpoint' },
				{ text: 'Microsoft Outlook', value: 'Microsoft Outlook' },
				{ text: 'AutoCad', value: 'AutoCad' },
				{ text: 'Google Docs', value: 'Google Docs' },
			],

			optionsProfi2: [
				{ text: 'English', value: 'English' },
				{ text: 'Mandarin Chinese', value: 'Mandarin Chinese' },
				{ text: 'Bahasa Melayu', value: 'Bahasa Melayu' },
				{ text: 'Bahasa Indonesia', value: 'Bahasa Indonesia' },
				{ text: 'Hindi', value: 'Hindi' },
				{ text: 'German', value: 'German' },
				{ text: 'French', value: 'French' },
				{ text: 'Spanish', value: 'Spanish' },
				{ text: 'Russian', value: 'Russian' },
			],
			selectedExperience_1: null,
			optionsExperience_1: [
				{ text: 'Yes', value: 'Yes (Proceed to Section G)' },
				{ text: 'No', value: 'No (proceed to Section F.2):' },
			],
			selectedExperience_2: null,
			optionsExperience_2: [
				{ text: 'Yes', value: 'Yes (Proceed to Section F.2)' },
				{ text: 'No', value: 'No (Proceed to Section G)' },
			],
			selectedStatus: {
				text: null,
				value: null
			},
			selectedNationlity: {
				text: null,
				value: null
			},
			optionsStatus: [
				{ text: 'Active', value: 1 },
				{ text: 'Inactive', value: 0 },
			],
			optionsNationlity: [
				{ text: 'Singapore', value: 'Singapore' },
				{ text: 'Permanent Resident', value: 'Permanent Resident' },
			],
			selectedRace: {
				text: null,
				value: null,
			},
			optionsRace: [
				{ text: 'Chinese', value: 'Chinese' },
				{ text: 'Malay', value: 'Malay' },
				{ text: 'Indian', value: 'Indian' },
			],
			selectedReligion: {
				text: null,
				value: null,
			},
			optionsReligion: [
				{ text: 'Buddhist', value: 'Buddhist' },
				{ text: 'Christian', value: 'Christian' },
				{ text: 'Hindu', value: 'Hindu' },
				{ text: 'Islam', value: 'Islam' },
			],
			selectMarital: {
				text: null,
				value: null,
			},
			optionsMarital: [
				{ text: 'Divorced', value: 'Divorced' },
				{ text: 'Married', value: 'Married' },
				{ text: 'Single', value: 'Single' },
				{ text: 'Widowed', value: 'Widowed' },
			],
			detail: [],
			step1: {},
			step2: {},
			step3: {},

			selectedCurStatus: null,
			optionsCurStatus: [
				{ text: 'Resigned from ITE', value: 'Resigned from ITE(Please specify last day at ITE MM-DD-YYYY):' },
				{ text: 'Retired from ITE', value: 'Retired from ITE(Please indicate your year of retirement from ITE):' },
			],

			selectedIteCurStatus: null,
			optionsIteCurStatus: [
				{ text: 'Yes', value: "Current ITE Staff(Please indicate your year of employment at ITE (YYYY)):" },
				{ text: 'No', value: 'No' },
			],

			editStaffExpert: {
				password: null,
				confirmPassword: null,
			},
			expertUsername: null,
			is_show: false,
			disabled_CurSatatus_1: true,
			disabled_CurSatatus_2: true,
			hideAnnexB: false
		}
	},
	created() {
		this.detailItem()
		this.permission = this.checkPermission(this.userData, "Staff Management");
		if (this.$route.name == 'staff-management-expert-detail') {
			this.labels = { 'staff-management-expert-detail': '' }
			this.typeExperts = { "staff-management-expert-detail": "" }
			this.tabTypes = { "staff-management-expert-detail": "" }
		} else {
			this.isDisabled = false
			this.is_show = true
		}

	},
	watch: {
		"$route": function (val) {
			if (val) {
				this.detailItem()
			}
		},
		selectedCurStatus(val) {
			if (val == 'Resigned from ITE(Please specify last day at ITE MM-DD-YYYY):') {
				this.disabled_CurSatatus_1 = false
				this.disabled_CurSatatus_2 = true
			}
			else {
				this.disabled_CurSatatus_1 = true
				this.disabled_CurSatatus_2 = false
			}
		}
	},
	methods: {
		toEdit(id) {
			this.$router.push(`/admin/staff-management/TVET-experts/edit/${id}`)
			this.isDisabled = false
			this.is_show = true
		},
		length20(e) {
			return String(e).substring(0, 20)
		},
		length50(e) {
			return String(e).substring(0, 50)
		},
		length75(e) {
			return String(e).substring(0, 75)
		},
		length100(e) {
			return String(e).substring(0, 100)
		},
		length1000(e) {
			return String(e).substring(0, 1000)
		},
		detailItem() {
			this.$store
				.dispatch('project/detailTVETExpert', {
					id: this.$route.params.id
				})
				.then(response => {
					this.labels['staff-management-expert-edit'] = response.data.data.eoi_name
					this.labels['staff-management-expert-detail'] = response.data.data.eoi_name
					document.title = response.data.data.eoi_name + ' | Staff Management - ITEES | Your Global Partner of Choice'

					this.detail = response.data.data
					this.step1 = response.data.data.page_first
					this.step2 = response.data.data.andex_current
					this.step3 = response.data.data.andex_past

					if (this.detail && this.detail.user && this.detail.user.username) {
						this.expertUsername = this.detail.user.username
					}

					if (this.detail && this.detail.eoi_type == 0) {
						this.typeExperts['staff-management-expert-edit'] = 'External'
						this.tabTypes['staff-management-expert-edit'] = 0
						this.typeExperts['staff-management-expert-detail'] = 'External'
						this.tabTypes['staff-management-expert-detail'] = 0
					}
					else if (this.detail && this.detail.eoi_type == 1) {
						this.typeExperts['staff-management-expert-edit'] = 'Ex-ITE'
						this.tabTypes['staff-management-expert-edit'] = 1
						this.typeExperts['staff-management-expert-detail'] = 'Ex-ITE'
						this.tabTypes['staff-management-expert-detail'] = 1
					}
					else {
						this.typeExperts['staff-management-expert-edit'] = 'ITE'
						this.tabTypes['staff-management-expert-edit'] = 2
						this.typeExperts['staff-management-expert-detail'] = 'ITE'
						this.tabTypes['staff-management-expert-detail'] = 2
					}

					if (this.detail && this.detail.andex_B1 && this.detail.andex_B1.length == 0 &&
						this.detail.andex_B2 && this.detail.andex_B2.length == 0 &&
						this.detail.andex_B3 && this.detail.andex_B3.length == 0
					) {
						this.hideAnnexB = true
					}
					else {
						this.hideAnnexB = false
					}

					if (this.detail && this.detail.user && this.detail.user.active == 0) {
						this.selectedStatus.text = 'Inactive'
						this.selectedStatus.value = 0
					}
					if (this.detail && this.detail.user && this.detail.user.active == 1) {
						this.selectedStatus.text = 'Active'
						this.selectedStatus.value = 1
					}

					if (this.step1.radio_travel_than_week[0] == 'Yes (Proceed to Question 3)') {
						this.selectedAnswer = 'Yes (Proceed to Question 3)'
					}
					else {
						this.selectedAnswer = 'No (Proceed to Section B'
						this.step1.text_travel_length = null
					}

					if (this.step1 && this.step1.text_personal_other_1 != null) {

						if (!this.optionsNationlity.find(arr1Obj => arr1Obj.value === this.step1.text_personal_other_1)) {
							this.optionsNationlity.push(
								{ text: this.step1.text_personal_other_1, value: this.step1.text_personal_other_1 }
							)
						}
					}

					if (this.step1.checkbox_personal_nationality == "Singapore") {
						this.selectedNationlity.text = 'Singapore'
						this.selectedNationlity.value = 'Singapore'
					}
					else if (this.step1.checkbox_personal_nationality == "Permanent Resident") {
						this.selectedNationlity.text = 'Permanent Resident'
						this.selectedNationlity.value = 'Permanent Resident'
					}
					else {
						this.selectedNationlity.text = this.step1.text_personal_other_1
						this.selectedNationlity.value = this.step1.text_personal_other_1
					}

					if (this.step1 && this.step1.text_personal_other_2 != null) {
						if (!this.optionsRace.find(arr1Obj => arr1Obj.value === this.step1.text_personal_other_2)) {
							this.optionsRace.push(
								{ text: this.step1.text_personal_other_2, value: this.step1.text_personal_other_2 }
							)
						}
					}

					if (this.step1.checkbox_personal_race == "Chinese") {
						this.selectedRace.text = 'Chinese'
						this.selectedRace.value = 'Chinese'
					}
					else if (this.step1.checkbox_personal_race == "Malay") {
						this.selectedRace.text = 'Malay'
						this.selectedRace.value = 'Malay'
					}
					else if (this.step1.checkbox_personal_race == "Indian") {
						this.selectedRace.text = 'Indian'
						this.selectedRace.value = 'Indian'
					}
					else {
						this.selectedRace.text = this.step1.text_personal_other_2
						this.selectedRace.value = this.step1.text_personal_other_2
					}

					if (this.step1 && this.step1.text_personal_other_3 != null) {
						if (!this.optionsReligion.find(arr1Obj => arr1Obj.value === this.step1.text_personal_other_3)) {
							this.optionsReligion.push(
								{ text: this.step1.text_personal_other_3, value: this.step1.text_personal_other_3 }
							)
						}
					}

					if (this.step1.checkbox_personal_religion == "Buddhist") {
						this.selectedReligion.text = 'Buddhist'
						this.selectedReligion.value = 'Buddhist'
					}
					else if (this.step1.checkbox_personal_religion == "Islam") {
						this.selectedReligion.text = 'Islam'
						this.selectedReligion.value = 'Islam'
					}
					else if (this.step1.checkbox_personal_religion == "Hindu") {
						this.selectedReligion.text = 'Hindu'
						this.selectedReligion.value = 'Hindu'
					}
					else if (this.step1.checkbox_personal_religion == "Christian") {
						this.selectedReligion.text = 'Christian'
						this.selectedReligion.value = 'Christian'
					}
					else {
						this.selectedReligion.text = this.step1.text_personal_other_3
						this.selectedReligion.value = this.step1.text_personal_other_3
					}

					if (this.step1.checkbox_personal_marital == "Single") {
						this.selectMarital.text = 'Single'
						this.selectMarital.value = 'Single'
					}
					else if (this.step1.checkbox_personal_marital == "Married") {
						this.selectMarital.text = 'Married'
						this.selectMarital.value = 'Married'
					}
					else if (this.step1.checkbox_personal_marital == "Divorced") {
						this.selectMarital.text = 'Divorced'
						this.selectMarital.value = 'Divorced'
					}
					else if (this.step1.checkbox_personal_marital == "Widowed") {
						this.selectMarital.text = 'Widowed'
						this.selectMarital.value = 'Widowed'
					}

					if (this.step1.text_experience_other != null || this.step1.radio_experience_1 == "No (proceed to Section F.2):") {
						this.selectedExperience_1 = 'No (proceed to Section F.2):'
					}
					else {
						this.selectedExperience_1 = 'Yes (Proceed to Section G)'
					}

					if (this.step1.radio_experience_2 == "No (Proceed to Section G)") {
						this.selectedExperience_2 = 'No (Proceed to Section G)'
					}
					else {
						this.selectedExperience_2 = 'Yes (Proceed to Section F.2)'
					}
					// 
					if (this.step1 && this.step1.radio_current_status && this.step1.radio_current_status == 'Resigned from ITE(Please specify last day at ITE MM-DD-YYYY):') {
						this.selectedCurStatus = 'Resigned from ITE(Please specify last day at ITE MM-DD-YYYY):'
					}
					else {
						this.selectedCurStatus = 'Retired from ITE(Please indicate your year of retirement from ITE):'
					}

					if (this.step1 && this.step1.radio_current_status && this.step1.radio_current_status == "Current ITE Staff(Please indicate your year of employment at ITE (YYYY)):") {
						this.selectedIteCurStatus = "Current ITE Staff(Please indicate your year of employment at ITE (YYYY)):"
					}
					else {
						this.selectedIteCurStatus = 'No'
					}
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
		proActivityModal() {
			this.$refs['ProActivity_ref'].show()
		},
		ActivityModal() {
			this.$refs['Activity_ref'].show()
		},
		ACurrentModal() {
			this.$refs['ACurrent_ref'].show()
		},
		APassModal() {
			this.$refs['APass_ref'].show()
		},
		editAction() {
			this.editing = true
			this.isEdit = true
			this.is_show = true
		},
		cancelEdit() {
			this.isDisabled = true
			this.editing = false
			this.isEdit = false
			this.is_show = false
			this.$router.push({ path: `/admin/staff-management/TVET-experts/${this.detail.id}` })
			// this.$router.push(`/admin/staff-management?tab=2&type=${this.detail.eoi_type}`)
		},
		saveEdit() {
			this.$refs.experts_ref.validate().then(success => {
				if (success) {
					if (this.selectedAnswer && this.selectedAnswer == 'No (Proceed to Section B') {
						this.step1.text_travel_length = null
					}
					let datas = {}
					if (this.detail) {
						if (this.detail && this.detail.eoi_type && this.detail.eoi_type == 1) {
							datas = {
								page_name: 'first',
								eoi_type: this.detail.eoi_type,
								date_form: this.step1.date_form,
								countries_name_1: this.step1.countries_name_1,
								countries_name_2: this.step1.countries_name_2,
								countries_name_3: this.step1.countries_name_3,
								countries_name_4: this.step1.countries_name_4,
								countries_name_5: this.step1.countries_name_5,
								radio_travel_than_week: this.selectedAnswer,
								text_travel_length: this.step1.text_travel_length,
								text_personal_family: this.step1.text_personal_family,
								text_personal_given: this.step1.text_personal_given,
								checkbox_personal_nationality: this.selectedNationlity.value,
								checkbox_personal_race: this.selectedRace.value,
								checkbox_personal_religion: this.selectedReligion.value,
								checkbox_personal_marital: this.selectMarital.value,
								date_personal_date: this.step1.date_personal_date,
								email_personal_email: this.step1.email_personal_email,
								text_personal_contact: this.step1.text_personal_contact,
								checkbox_proficiencies_1: this.step1.checkbox_proficiencies_1,
								text_proficiencies_other_1: this.step1.text_proficiencies_other_1,
								checkbox_proficiencies_2: this.step1.checkbox_proficiencies_2,
								text_proficiencies_other_2: this.step1.text_proficiencies_other_2,

								radio_current_status: this.selectedCurStatus,
								text_current_status_1: this.step1.text_current_status_1,
								text_current_status_2: this.step1.text_current_status_2,

								text_e_designation_1: this.step1.text_e_designation_1,
								text_e_designation_2: this.step1.text_e_designation_2,
								text_e_length_1: this.step1.text_e_length_1,
								text_e_length_2: this.step1.text_e_length_2,
								text_e_key_1: this.step1.text_e_key_1,
								text_e_key_2: this.step1.text_e_key_2,
								text_e_referee_name_1: this.step1.text_e_referee_name_1,
								text_e_referee_contact_2: this.step1.text_e_referee_contact_2,
								radio_experience_1: this.selectedExperience_1,
								text_experience_other: this.step1.text_experience_other,
								radio_experience_2: this.selectedExperience_2,
								text_experience_title: this.step1.text_experience_title,
								cb_experience_training: this.step1.cb_experience_training,
								cb_experience_consultancy: this.step1.cb_experience_consultancy,
								text_experience_yeah_appointment_1: this.step1.text_experience_yeah_appointment_1,
								text_experience_yeah_appointment_2: this.step1.text_experience_yeah_appointment_2,
								text_experience_job_role_1: this.step1.text_experience_job_role_1,
								text_experience_job_role_2: this.step1.text_experience_job_role_2,
								text_experience_key_respon_1: this.step1.text_experience_key_respon_1,
								text_experience_key_respon_2: this.step1.text_experience_key_respon_2,
								cb_interests_training: this.step1.cb_interests_training,
								cb_interests_consultancy: this.step1.cb_interests_consultancy,
								text_interests_other_remarks: this.step1.text_interests_other_remarks,

								text_personal_other_1: this.step1.text_personal_other_1,
								text_personal_other_2: this.step1.text_personal_other_2,
								text_personal_other_3: this.step1.text_personal_other_3,

								username: this.expertUsername,
								password: this.editStaffExpert.password,
								confirm_password: this.editStaffExpert.confirmPassword,
								status: this.selectedStatus.value,
							}
						}
						else if (this.detail && this.detail.eoi_type && this.detail.eoi_type == 2) {
							datas = {
								page_name: 'first',
								eoi_type: this.detail.eoi_type,
								date_form: this.step1.date_form,
								countries_name_1: this.step1.countries_name_1,
								countries_name_2: this.step1.countries_name_2,
								countries_name_3: this.step1.countries_name_3,
								countries_name_4: this.step1.countries_name_4,
								countries_name_5: this.step1.countries_name_5,
								radio_travel_than_week: this.selectedAnswer,
								text_travel_length: this.step1.text_travel_length,
								text_personal_family: this.step1.text_personal_family,
								text_personal_given: this.step1.text_personal_given,
								checkbox_personal_nationality: this.selectedNationlity.value,
								checkbox_personal_race: this.selectedRace.value,
								checkbox_personal_religion: this.selectedReligion.value,
								checkbox_personal_marital: this.selectMarital.value,
								date_personal_date: this.step1.date_personal_date,
								email_personal_email: this.step1.email_personal_email,
								text_personal_contact: this.step1.text_personal_contact,
								checkbox_proficiencies_1: this.step1.checkbox_proficiencies_1,
								text_proficiencies_other_1: this.step1.text_proficiencies_other_1,
								checkbox_proficiencies_2: this.step1.checkbox_proficiencies_2,
								text_proficiencies_other_2: this.step1.text_proficiencies_other_2,

								radio_current_status: this.selectedCurStatus,
								text_status: this.step1.text_status,

								text_e_designation: this.step1.text_e_designation,
								text_e_length: this.step1.text_e_length,
								text_e_key: this.step1.text_e_key,
								text_e_referee_name_1: this.step1.text_e_referee_name_1,
								text_e_referee_contact_2: this.step1.text_e_referee_contact_2,

								radio_experience_1: this.selectedExperience_1,
								text_experience_other: this.step1.text_experience_other,
								radio_experience_2: this.selectedExperience_2,
								text_experience_title: this.step1.text_experience_title,
								cb_experience_training: this.step1.cb_experience_training,
								cb_experience_consultancy: this.step1.cb_experience_consultancy,
								text_experience_yeah_appointment_1: this.step1.text_experience_yeah_appointment_1,
								text_experience_yeah_appointment_2: this.step1.text_experience_yeah_appointment_2,
								text_experience_job_role_1: this.step1.text_experience_job_role_1,
								text_experience_job_role_2: this.step1.text_experience_job_role_2,
								text_experience_key_respon_1: this.step1.text_experience_key_respon_1,
								text_experience_key_respon_2: this.step1.text_experience_key_respon_2,
								cb_interests_training: this.step1.cb_interests_training,
								cb_interests_consultancy: this.step1.cb_interests_consultancy,
								text_interests_other_remarks: this.step1.text_interests_other_remarks,

								text_personal_other_1: this.step1.text_personal_other_1,
								text_personal_other_2: this.step1.text_personal_other_2,
								text_personal_other_3: this.step1.text_personal_other_3,

								username: this.expertUsername,
								password: this.editStaffExpert.password,
								confirm_password: this.editStaffExpert.confirmPassword,
								status: this.selectedStatus.value,
							}
						}
						else {
							datas = {
								page_name: 'first',
								eoi_type: this.detail.eoi_type,
								date_form: this.step1.date_form,
								countries_name_1: this.step1.countries_name_1,
								countries_name_2: this.step1.countries_name_2,
								countries_name_3: this.step1.countries_name_3,
								countries_name_4: this.step1.countries_name_4,
								countries_name_5: this.step1.countries_name_5,
								radio_travel_than_week: this.selectedAnswer,
								text_travel_length: this.step1.text_travel_length,
								text_personal_family: this.step1.text_personal_family,
								text_personal_given: this.step1.text_personal_given,
								checkbox_personal_nationality: this.selectedNationlity.value,
								checkbox_personal_race: this.selectedRace.value,
								checkbox_personal_religion: this.selectedReligion.value,
								checkbox_personal_marital: this.selectMarital.value,
								date_personal_date: this.step1.date_personal_date,
								email_personal_email: this.step1.email_personal_email,
								text_personal_contact: this.step1.text_personal_contact,
								checkbox_proficiencies_1: this.step1.checkbox_proficiencies_1,
								text_proficiencies_other_1: this.step1.text_proficiencies_other_1,
								checkbox_proficiencies_2: this.step1.checkbox_proficiencies_2,
								text_proficiencies_other_2: this.step1.text_proficiencies_other_2,
								text_qua_name: this.step1.text_qua_name,
								text_qua_country: this.step1.text_qua_country,
								text_qua_course: this.step1.text_qua_course,
								text_qua_graduation: this.step1.text_qua_graduation,
								text_qua_attained: this.step1.text_qua_attained,
								text_profession_name_1: this.step1.text_profession_name_1,
								text_profession_name_2: this.step1.text_profession_name_2,
								text_profession_industry_1: this.step1.text_profession_industry_1,
								text_profession_industry_2: this.step1.text_profession_industry_2,
								text_profession_business_1: this.step1.text_profession_business_1,
								text_profession_business_2: this.step1.text_profession_business_2,
								text_profession_service_1: this.step1.text_profession_service_1,
								text_profession_service_2: this.step1.text_profession_service_2,
								text_profession_job_1: this.step1.text_profession_job_1,
								text_profession_job_2: this.step1.text_profession_job_2,
								text_profession_scope_1: this.step1.text_profession_scope_1,
								text_profession_scope_2: this.step1.text_profession_scope_2,
								text_profession_referee_name_1: this.step1.text_profession_referee_name_1,
								text_profession_referee_contact_1: this.step1.text_profession_referee_contact_1,
								text_profession_referee_name_2: this.step1.text_profession_referee_name_2,
								text_profession_referee_contact_2: this.step1.text_profession_referee_contact_2,
								radio_experience_1: this.selectedExperience_1,
								text_experience_other: this.step1.text_experience_other,
								radio_experience_2: this.selectedExperience_2,
								text_experience_title: this.step1.text_experience_title,
								cb_experience_training: this.step1.cb_experience_training,
								cb_experience_consultancy: this.step1.cb_experience_consultancy,
								text_experience_yeah_appointment_1: this.step1.text_experience_yeah_appointment_1,
								text_experience_yeah_appointment_2: this.step1.text_experience_yeah_appointment_2,
								text_experience_job_role_1: this.step1.text_experience_job_role_1,
								text_experience_job_role_2: this.step1.text_experience_job_role_2,
								text_experience_key_respon_1: this.step1.text_experience_key_respon_1,
								text_experience_key_respon_2: this.step1.text_experience_key_respon_2,
								cb_interests_training: this.step1.cb_interests_training,
								cb_interests_consultancy: this.step1.cb_interests_consultancy,
								text_interests_other_remarks: this.step1.text_interests_other_remarks,

								text_personal_other_1: this.step1.text_personal_other_1,
								text_personal_other_2: this.step1.text_personal_other_2,
								text_personal_other_3: this.step1.text_personal_other_3,

								username: this.expertUsername,
								password: this.editStaffExpert.password,
								confirm_password: this.editStaffExpert.confirmPassword,
								status: this.selectedStatus.value,
							}
						}
					}
					// console.log('data Ex: ', datas)
					this.$store
						.dispatch('project/editStaffExpert',
							{
								id: this.$route.params.id,
								data: datas
							})
						.then(response => {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: `${this.step1.text_personal_given} ${this.step1.text_personal_family} Updated!`,
									variant: 'success',
								},
							},
								{
									position: 'top-center'
								}
							)
							this.detailItem()
							this.isEdit = false
							this.editing = false
							this.is_show = false
						})
						.catch((error) => {
							console.log(error)
							this.$toast({
								component: ToastificationContent,
								props: {
									title: error.response.data.message,
									variant: 'danger',
								},
							},
								{
									position: 'top-center'
								}
							)
						})
				}
				else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'One or more fields have an error. Please check and try again.',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
				}
			})
		},
	}
};

</script>

<style lang="scss">	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	#modalProActivity .modal-dialog,
	#ActivityModal .modal-dialog,
	#modalACurrent .modal-dialog,
	#modalAPass .modal-dialog {
		max-width: 70%
	}

	.modal ul.tranning {
		padding-left: 21px;
		display: flex;
		flex-wrap: wrap;
	}

	.modal ul.tranning li {
		list-style-image: url(../../../../../assets/images/itees/admin/checkbox_checked.png);
		width: 50%;
		margin-bottom: 10px;
		padding-right: 15px
	}

	@meida only sreen and (max-width: 480px) {

		#modalProActivity .modal-dialog,
		#ActivityModal .modal-dialog,
		#modalACurrent .modal-dialog,
		#modalAPass .modal-dialog {
			max-width: 90%
		}
	}
</style>
