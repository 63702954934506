<template>
	<section>
		<b-form @submit.prevent class="profile-frm hasshadow" style="border-radius: 5px; margin-top: 10px">
			<b-row style="width: 100%; margin: 0;">
				<b-col lg="4" class="frm-basic">
					<b-form-group class="w-100 size-16">
						<v-select v-model="selectedAnnexB" :options="optionsAnnexB" label="text" />
					</b-form-group>
				</b-col>
				<b-col lg="8" class="px-0">
					<div class="text-right mb-2">
						<template v-if="(userData && permission.edit)">

							<b-button type="button" variant="primary" class="btn-df size-18 black" v-if="!isEdit"
								@click="editAction">
								Edit
							</b-button>
							<b-button type="button" variant="basic" class="btn-df size-18 black" v-if="isEdit"
								@click="cancelEdit"
								style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
							<b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveEditAnnexB()"
								v-if="isEdit">
								Save
							</b-button>
						</template>
						<template v-else>
							<b-button type="button" variant="primary" class="btn-df size-18 btn-disabled-gray" disabled>
								Edit
							</b-button>
						</template>
					</div>
				</b-col>
			</b-row>
			<div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
			<div class="frm-basic size-16 d-block">
				<template v-if="selectedAnnexB.value == 1">
					<b-row style="width: 100%; margin: 0;">
						<b-col lg="12" class="px-0">
							<p style="color: #8471FF" class="bold mb-1 pb-1">F.2 Experience with ITEES - Part 2</p>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-mb-26">
							<b-form-group class="mb-0" label-class="size-18">
								<b-form-group label="Project Title" label-cols-lg="2" class="input-group-label">
									<b-form-input v-model="step4.texa_project_title" placeholder="Title"
										:formatter="length500" :disabled="isDisabled"></b-form-input>
								</b-form-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold">2. Project Activity</p>
							<p style="color: #29D3B5" class="bold mb-1 pb-1">Training</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step4.cb_training" :options="optionsTraining"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #29D3B5" class="bold mb-1 pb-1">Consultancy</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step4.cb_consultancy" :options="optionsConsultancy"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">3. Year of Appointment</p>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="1." label-cols-lg="4" class="input-group-label">
								<b-form-input type="number" v-model="step4.text_yeah_appointment_1"
									placeholder="Year of Appointment" :formatter="length75"
									:disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="2." label-cols-lg="4" class="input-group-label">
								<b-form-input type="number" v-model="step4.text_yeah_appointment_2"
									placeholder="Year of Appointment" :formatter="length75"
									:disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">4. Job Role</p>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="1." label-cols-lg="4" class="input-group-label">
								<b-form-input v-model="step4.text_job_role_1" placeholder="Job Role" :formatter="length75"
									:disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="2." label-cols-lg="4" class="input-group-label">
								<b-form-input v-model="step4.text_job_role_2" placeholder="Job Role" :formatter="length75"
									:disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">5. Key Job Scope and Responsibilities</p>
							<b-form-group label="1." label-cols-lg="2" class="mb-2 pb-1" label-class="size-18">
								<b-form-textarea v-model="step4.text_key_respon_1"
									placeholder="Key Job Scope and Responsibilities" rows="5" :formatter="length100"
									:disabled="isDisabled"></b-form-textarea>
							</b-form-group>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<b-form-group label="2." label-cols-lg="2" class="mb-2 pb-1" label-class="size-18">
								<b-form-textarea v-model="step4.text_key_respon_2"
									placeholder="Key Job Scope and Responsibilities" rows="5" :formatter="length100"
									:disabled="isDisabled"></b-form-textarea>
							</b-form-group>
						</b-col>
					</b-row>
				</template>
				<template v-else-if="selectedAnnexB.value == 2">
					<b-row style="width: 100%; margin: 0;">
						<b-col lg="12" class="px-0">
							<p style="color: #8471FF" class="bold mb-1 pb-1">F.2 Experience with ITEES - Part 2</p>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-mb-26">
							<b-form-group class="mb-0" label-class="size-18">
								<b-form-group label="Project Title" label-cols-lg="2" class="input-group-label">
									<b-form-input v-model="step5.texa_b2_project_title" placeholder="Title"
										:formatter="length500" :disabled="isDisabled"></b-form-input>
								</b-form-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold">2. Project Activity</p>
							<p style="color: #29D3B5" class="bold mb-1 pb-1">Training</p>
							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step5.cb_b2_training" :options="optionsTraining"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #29D3B5" class="bold mb-1 pb-1">Consultancy</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step5.cb_b2_consultancy" :options="optionsConsultancy"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">3. Year of Appointment</p>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="1." label-cols-lg="4" class="input-group-label">
								<b-form-input type="number" v-model="step5.text_b2_yeah_appointment_1"
									placeholder="Year of Appointment" :formatter="length75"
									:disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="2." label-cols-lg="4" class="input-group-label">
								<b-form-input type="number" v-model="step5.text_b2_yeah_appointment_2"
									placeholder="Year of Appointment" :formatter="length75"
									:disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">4. Job Role</p>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="1." label-cols-lg="4" class="input-group-label">
								<b-form-input v-model="step5.text_b2_job_role_1" placeholder="Job Role"
									:formatter="length75" :disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="2." label-cols-lg="4" class="input-group-label">
								<b-form-input v-model="step5.text_b2_job_role_2" placeholder="Job Role"
									:formatter="length75" :disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">5. Key Job Scope and Responsibilities</p>
							<b-form-group label="1." label-cols-lg="2" class="mb-2 pb-1" label-class="size-18">
								<b-form-textarea v-model="step5.text_b2_key_respon_1"
									placeholder="Key Job Scope and Responsibilities" rows="5" :formatter="length100"
									:disabled="isDisabled"></b-form-textarea>
							</b-form-group>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<b-form-group label="2." label-cols-lg="2" class="mb-2 pb-1" label-class="size-18">
								<b-form-textarea v-model="step5.text_b2_key_respon_2"
									placeholder="Key Job Scope and Responsibilities" rows="5" :formatter="length100"
									:disabled="isDisabled"></b-form-textarea>
							</b-form-group>
						</b-col>
					</b-row>
				</template>
				<template v-else>
					<b-row style="width: 100%; margin: 0;">
						<b-col lg="12" class="px-0">
							<p style="color: #8471FF" class="bold mb-1 pb-1">F.2 Experience with ITEES - Part 2</p>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-mb-26">
							<b-form-group class="mb-0" label-class="size-18">
								<b-form-group label="Project Title" label-cols-lg="2" class="input-group-label">
									<b-form-input v-model="step6.texa_b3_project_title" placeholder="Title"
										:formatter="length500" :disabled="isDisabled"></b-form-input>
								</b-form-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold">2. Project Activity</p>
							<p style="color: #29D3B5" class="bold mb-1 pb-1">Training</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step6.cb_b3_training" :options="optionsTraining"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #29D3B5" class="bold mb-1 pb-1">Consultancy</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step6.cb_b3_consultancy" :options="optionsConsultancy"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">3. Year of Appointment</p>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="1." label-cols-lg="4" class="input-group-label">
								<b-form-input type="number" v-model="step6.text_b3_yeah_appointment_1"
									placeholder="Year of Appointment" :formatter="length75"
									:disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="2." label-cols-lg="4" class="input-group-label">
								<b-form-input type="number" v-model="step6.text_b3_yeah_appointment_2"
									placeholder="Year of Appointment" :formatter="length75"
									:disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">4. Job Role</p>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="1." label-cols-lg="4" class="input-group-label">
								<b-form-input v-model="step6.text_b3_job_role_1" placeholder="Job Role"
									:formatter="length75" :disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="2." label-cols-lg="4" class="input-group-label">
								<b-form-input v-model="step6.text_b3_job_role_2" placeholder="Job Role"
									:formatter="length75" :disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">5. Key Job Scope and Responsibilities</p>
							<b-form-group label="1." label-cols-lg="2" class="mb-2 pb-1" label-class="size-18">
								<b-form-textarea v-model="step6.text_b3_key_respon_1"
									placeholder="Key Job Scope and Responsibilities" rows="5" :formatter="length100"
									:disabled="isDisabled"></b-form-textarea>
							</b-form-group>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-30 cb-mb-26">
							<b-form-group label="2." label-cols-lg="2" class="mb-2 pb-1" label-class="size-18">
								<b-form-textarea v-model="step6.text_b3_key_respon_2"
									placeholder="Key Job Scope and Responsibilities" rows="5" :formatter="length100"
									:disabled="isDisabled"></b-form-textarea>
							</b-form-group>
						</b-col>
					</b-row>
				</template>
			</div>
		</b-form>
	</section>
</template>
<script>
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import globalAdmin from '../../model/globalAdmin'
export default {
	mixins: [globalAdmin],
	components: {
		vSelect
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			detail: [],
			step4: {},
			step5: {},
			step6: {},

			isDisabled: true,
			isEdit: false,
			editing: false,
			disable_editActive: false,

			optionsTraining: [
				{ text: 'Train-The-Trainers (Pedagogy)', value: 'Train-The-Trainers (Pedagogy)' },
				{ text: 'Train-The-Trainers (Technical) / Technical Skills Upgrading', value: 'Train-The-Trainers (Technical) / Technical Skills Upgrading' },
				{ text: 'Campus Planning and Development', value: 'Campus Planning and Development' },
				{ text: 'Curriculum Design & Development', value: 'Curriculum Design & Development' },
				{ text: 'Developing a Curriculum (DACUM)', value: 'Developing a Curriculum (DACUM)' },
				{ text: 'Continuing Education and Training (CET) System', value: 'Continuing Education and Training (CET) System' },
				{ text: 'Structured Internship Framework', value: 'Structured Internship Framework' },
				{ text: 'Developing Question Bank System', value: 'Developing Question Bank System' },
				{ text: 'Coaching Skills', value: 'Coaching Skills' },
				{ text: 'Design and Develop On-The-Job Training', value: 'Design and Develop On-The-Job Training' },
				{ text: 'Develop and Conduct Assessment', value: 'Develop and Conduct Assessment' },
				{ text: 'Development of Training Materials', value: 'Development of Training Materials' },
				{ text: 'Development of Academic Structure', value: 'Development of Academic Structure' },
				{ text: 'Industry-based Training', value: 'Industry-based Training' },
				{ text: 'Leaders Training Workshop', value: 'Leaders Training Workshop' },
				{ text: 'Training Supervision', value: 'Training Supervision' },
				{ text: 'Skills Evaluation Test (Local)', value: 'Skills Evaluation Test (Local)' },
			],
			optionsConsultancy: [
				{ text: 'Advice on Infrastructure Development', value: 'Advice on Infrastructure Development' },
				{ text: 'Curriculum Planning and Adaptation', value: 'Curriculum Planning and Adaptation' },
				{ text: 'Licensing', value: 'Licensing' },
				{ text: 'Examination (Supervision/Invigilation)', value: 'Examination (Supervision/Invigilation)' },
				{ text: 'Evaluation of Trainers', value: 'Evaluation of Trainers' },
				{ text: 'Review of Centre Performance / Evaluation of Centre Performance', value: 'Review of Centre Performance / Evaluation of Centre Performance' },
				{ text: 'Site Audit and Evaluation', value: 'Site Audit and Evaluation' },
				{ text: 'Supervision of Addition and Amendment Works', value: 'Supervision of Addition and Amendment Works' },
				{ text: 'Installation and Commissioning of Workshop', value: 'Installation and Commissioning of Workshop' },
				{ text: 'Procurement of Equipment', value: 'Procurement of Equipment' },
				{ text: 'Feasibility Study / Situation Analysis', value: 'Feasibility Study / Situation Analysis' },
				{ text: 'Overseas Deployment', value: 'Overseas Deployment' },
			],
			selectedAnnexB: {
				text: 'ANNEX - B1',
				value: 1
			},
			optionsAnnexB: [],
			dataSelect: {
				"andex_b1": { text: 'ANNEX - B1', value: 1 },
				"andex_b2": { text: 'ANNEX - B2', value: 2 },
				"andex_b3": { text: 'ANNEX - B3', value: 3 },
			},
		}
	},
	props: {
		permission: {
			type: [Array, String, Number, Object],
		}
	},
	created() {
		this.detailItem()
	},
	methods: {
		toEdit(id) {
			this.$router.push(`/admin/staff-management/TVET-experts/edit/${id}`)
		},
		length75(e) {
			return String(e).substring(0, 75)
		},
		length500(e) {
			return String(e).substring(0, 75)
		},
		length100(e) {
			return String(e).substring(0, 100)
		},
		editAction() {
			this.isDisabled = false
			this.editing = true
			this.isEdit = true
		},
		cancelEdit() {
			this.isDisabled = true
			this.editing = false
			this.isEdit = false
		},
		detailItem() {
			if(!this.permission.read){
				return;
			}
			this.$store
				.dispatch('project/detailTVETExpert', {
					id: this.$route.params.id
				})
				.then(response => {
					this.optionsAnnexB = []
					response.data.data.andexB.forEach((value, index) => {
						if (!this.selectedAnnexB) {
							this.selectedAnnexB = {
								text: this.dataSelect[`${value}`].text,
								value: this.dataSelect[`${value}`].value
							}
						}
						this.optionsAnnexB.push({ text: this.dataSelect[`${value}`].text, value: this.dataSelect[`${value}`].value });
					});
					this.detail = response.data.data
					this.step4 = response.data.data.andex_B1
					this.step5 = response.data.data.andex_B2
					this.step6 = response.data.data.andex_B3
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
		saveEditAnnexB() {
			let datas_B = {}
			if (this.selectedAnnexB.value == 1) {
				datas_B = {
					page_name: 'andex_B1',
					texa_project_title: this.step4.texa_project_title,
					cb_training: this.step4.cb_training,
					cb_consultancy: this.step4.cb_consultancy,
					text_yeah_appointment_1: this.step4.text_yeah_appointment_1,
					text_yeah_appointment_2: this.step4.text_yeah_appointment_2,
					text_job_role_1: this.step4.text_job_role_1,
					text_job_role_2: this.step4.text_job_role_2,
					text_key_respon_1: this.step4.text_key_respon_1,
					text_key_respon_2: this.step4.text_key_respon_2,
				}
			}
			else if (this.selectedAnnexB.value == 2) {
				datas_B = {
					page_name: 'andex_B2',
					texa_b2_project_title: this.step5.texa_b2_project_title,
					cb_b2_training: this.step5.cb_b2_training,
					cb_b2_consultancy: this.step5.cb_b2_consultancy,
					text_b2_yeah_appointment_1: this.step5.text_b2_yeah_appointment_1,
					text_b2_yeah_appointment_2: this.step5.text_b2_yeah_appointment_1,
					text_b2_job_role_1: this.step5.text_b2_job_role_1,
					text_b2_job_role_2: this.step5.text_b2_job_role_2,
					text_b2_key_respon_1: this.step5.text_b2_key_respon_1,
					text_b2_key_respon_2: this.step5.text_b2_key_respon_2,
				}
			}
			else {
				datas_B = {
					page_name: 'andex_B3',
					texa_b3_project_title: this.step6.texa_b3_project_title,
					cb_b3_training: this.step6.cb_b3_training,
					cb_b3_consultancy: this.step6.cb_b3_consultancy,
					text_b3_yeah_appointment_1: this.step6.text_b3_yeah_appointment_1,
					text_b3_yeah_appointment_2: this.step6.text_b3_yeah_appointment_2,
					text_b3_job_role_1: this.step6.text_b3_job_role_1,
					text_b3_job_role_2: this.step6.text_b3_job_role_2,
					text_b3_key_respon_1: this.step6.text_b3_key_respon_1,
					text_b3_key_respon_2: this.step6.text_b3_key_respon_2,
				}
			}
			console.log("Datas Annex B: ", datas_B)
			this.$store
				.dispatch('project/editTVETExpert',
					{
						id: this.$route.params.id,
						data: datas_B
					})
				.then(response => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: this.detail.eoi_name + ' Updated!',
							variant: 'success',
						},
					},
						{
							position: 'top-center'
						}
					)
					this.detailItem()
					this.isDisabled = true
					this.isEdit = false
					this.editing = false
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
	}
}
</script>