<template>
	<section>
		<b-form @submit.prevent class="profile-frm hasshadow" style="border-radius: 5px; margin-top: 10px">
			<b-row style="width: 100%; margin: 0;">
				<b-col lg="4" class="frm-basic">
					<b-form-group class="w-100 size-16">
						<v-select v-model="selectedAnnexA" :options="optionsAnnexA" label="text" />
					</b-form-group>
				</b-col>
				<b-col lg="8" class="px-0">
					<div class="text-right mb-2">
						<template v-if="(userData && permission.edit)">

							<b-button type="button" variant="primary" class="btn-df size-18 black" v-if="!isEdit"
								@click="editAction">
								Edit
							</b-button>
							<b-button type="button" variant="basic" class="btn-df size-18 black" v-if="isEdit"
								@click="cancelEdit"
								style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
							<b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveEditStep2()"
								v-if="isEdit">
								Save
							</b-button>
						</template>
						<template v-else>
							<b-button type="button" variant="primary" class="btn-df size-18 btn-disabled-gray" disabled>
								Edit
							</b-button>
						</template>
					</div>
				</b-col>
			</b-row>
			<div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
			<div class="frm-basic size-16 d-block">
				<template v-if="selectedAnnexA.value == 1">
					<b-row style="width: 100%; margin: 0;">
						<b-col lg="12" class="px-0">
							<p style="color: #8471FF" class="bold mb-1 pb-1">1. Applied and Health Sciences</p>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_applied_health" :options="optionsApplied_health"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">2. Engineering</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_engineering" :options="optionsEngineering"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">3. Info-Comm Technology and Electronics</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_tech_electronics"
									:options="optionsTech_electronics" :disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">4. Business and Services</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_business_services"
									:options="optionsBusiness_services" :disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">5. Design and Media</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_design_media" :options="optionsDesign_media"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">6. Hospitality</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_hospitality" :options="optionsHospitality"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">7. Life Skills</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_life_skill" :options="optionsLife_skill"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">8. Quality Assurance - TVET Excellence</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_quality" :options="optionsQuality"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">9. Train-The-Trainer (TTT) Programmes</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_train" :options="optionsTrain"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">10. Pedagogical Approaches</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_pedadogical" :options="optionsPedadogical"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">11. TVET Leadership</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_leader" :options="optionsLeader"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">12. Emerging Technology</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step2.cb_tech" :options="optionsTech"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">13. Others (Please specify here, if none of the
								above)</p>

							<b-form-group label="Others(if any)" label-cols-lg="2" class="mb-2 pb-1 input-group-label"
								label-class="size-18">
								<b-form-input v-model="step2.textarea_other" placeholder="Optional" rows="2"
									:formatter="length100" :disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>
				</template>
				<template v-else>
					<b-row style="width: 100%; margin: 0;">
						<b-col lg="12" class="px-0">
							<p style="color: #8471FF" class="bold mb-1 pb-1">1. Applied and Health Sciences</p>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_applied_health" :options="optionsApplied_health"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">2. Engineering</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_engineering" :options="optionsEngineering"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">3. Info-Comm Technology and Electronics</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_tech_electronics"
									:options="optionsTech_electronics" :disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">4. Business and Services</p>
							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_business_services"
									:options="optionsBusiness_services" :disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">5. Design and Media</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_design_media" :options="optionsDesign_media"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">6. Hospitality</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_hospitality" :options="optionsHospitality"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">7. Life Skills</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_life_skill" :options="optionsLife_skill"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">8. Quality Assurance - TVET Excellence</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_quality" :options="optionsQuality"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">9. Train-The-Trainer (TTT) Programmes</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_train" :options="optionsTrain"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">10. Pedagogical Approaches</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_pedadogical" :options="optionsPedadogical"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">11. TVET Leadership</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_leader" :options="optionsLeader"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">12. Emerging Technology</p>

							<b-form-group class="star-required mb-0" label-class="size-18">
								<b-form-checkbox-group v-model="step3.cb_tech" :options="optionsTech"
									:disabled="isDisabled">
								</b-form-checkbox-group>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; padding-bottom: 5px" class="mb-2"></div>
						</b-col>
						<b-col lg="12" class="px-0 cb-w-25 cb-w-25-pr-15 cb-mb-26">
							<p style="color: #8471FF" class="bold mb-1 pb-1">13. Others (Please specify here, if none of the
								above)</p>

							<b-form-group label="Others(if any)" label-cols-lg="2" class="mb-2 pb-1 input-group-label"
								label-class="size-18">
								<b-form-input v-model="step3.textarea_other" placeholder="Optional" rows="2"
									:formatter="length100" :disabled="isDisabled"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>
				</template>
			</div>
		</b-form>
	</section>
</template>
<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import globalAdmin from '../../model/globalAdmin'

export default {
	mixins: [globalAdmin],
	components: {
		ValidationProvider,
		ValidationObserver,
		vSelect
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			detail: [],
			step2: {},
			step3: {},

			isDisabled: true,
			isEdit: false,
			editing: false,
			disable_editActive: false,

			optionsApplied_health: [
				{ text: 'Biotechnology', value: 'Biotechnology' },
				{ text: 'Chemical Technology', value: 'Chemical Technology' },
				{ text: 'Paramedic & Emergency Care', value: 'Paramedic & Emergency Care' },
				{ text: 'Applied Food Science', value: 'Applied Food Science' },
				{ text: 'Chemical Process Technology', value: 'Chemical Process Technology' },
				{ text: 'Community Care & Social Services', value: 'Community Care & Social Services' },
				{ text: 'Nursing', value: 'Nursing' },
				{ text: 'Opticianry', value: 'Opticianry' },
			],
			optionsEngineering: [
				{ text: 'Automotive Engineering', value: 'Automotive Engineering' },
				{ text: 'Civil & Structural Engineering Design', value: 'Civil & Structural Engineering Design' },
				{ text: 'Electrical Engineering', value: 'Electrical Engineering' },
				{ text: 'Engineering with Business', value: 'Engineering with Business' },
				{ text: 'Facility Management', value: 'Facility Management' },
				{ text: 'Integrated Mechanical & Electrical Design', value: 'Integrated Mechanical & Electrical Design' },
				{ text: 'Facility Systems Design', value: 'Facility Systems Design' },
				{ text: 'Landscape Management & Design', value: 'Landscape Management & Design' },
				{ text: 'Marine Engineering', value: 'Marine Engineering' },
				{ text: 'Marine & Offshore Technology', value: 'Marine & Offshore Technology' },
				{ text: 'Mechanical Engineering', value: 'Mechanical Engineering' },
				{ text: 'Offshore & Marine Engineering Design', value: 'Offshore & Marine Engineering Design' },
				{ text: 'Precision Engineering', value: 'Precision Engineering' },
				{ text: 'Process Plant Design', value: 'Process Plant Design' },
				{ text: 'Rapid Transit Engineering', value: 'Rapid Transit Engineering' },
				{ text: 'Robotics & Smart Systems', value: 'Robotics & Smart Systems' },
				{ text: 'Aerospace Avionics', value: 'Aerospace Avionics' },
				{ text: 'Aerospace Machining Technology', value: 'Aerospace Machining Technology' },
				{ text: 'Aerospace Technology', value: 'Aerospace Technology' },
				{ text: 'Automotive Technology (Heavy Vehicles)', value: 'Automotive Technology (Heavy Vehicles)' },
				{ text: 'Automotive Technology (Light Vehicles)', value: 'Automotive Technology (Light Vehicles)' },
				{ text: 'Built Environment (Mechanical & Electrical Services)', value: 'Built Environment (Mechanical & Electrical Services)' },
				{ text: 'Built Environment (Vertical Transportation)', value: 'Built Environment (Vertical Transportation)' },
				{ text: 'Digital & Precision Engineering', value: 'Digital & Precision Engineering' },
				{ text: 'Electrical Technology (Lighting & Sound)', value: 'Electrical Technology (Lighting & Sound)' },
				{ text: 'Electrical Technology (Power & Control)', value: 'Electrical Technology (Power & Control)' },
				{ text: 'Mechanical Technology', value: 'Mechanical Technology' },
				{ text: 'Mechatronics & Robotics', value: 'Mechatronics & Robotics' },
				{ text: 'Rapid Transit Technology', value: 'Rapid Transit Technology' },
				{ text: 'Urban Greenery & Landscape', value: 'Urban Greenery & Landscape' },
			],
			optionsTech_electronics: [
				{ text: 'Broadcast & Media Technology', value: 'Broadcast & Media Technology' },
				{ text: 'Business Information Systems', value: 'Business Information Systems' },
				{ text: 'Cyber & Network Security', value: 'Cyber & Network Security' },
				{ text: 'Electronics Engineering', value: 'Electronics Engineering' },
				{ text: 'Games Art & Design', value: 'Games Art & Design' },
				{ text: 'Games Programming & Development', value: 'Games Programming & Development' },
				{ text: 'IT Applications Development', value: 'IT Applications Development' },
				{ text: 'IT Systems & Networks', value: 'IT Systems & Networks' },
				{ text: 'Security System Integration', value: 'Security System Integration' },
				{ text: 'Electronics & Internet of Things', value: 'Electronics & Internet of Things' },
				{ text: 'Electronics, Computer Networking & Communications', value: 'Electronics, Computer Networking & Communications' },
				{ text: 'Infocomm Technology', value: 'Infocomm Technology' },
				{ text: 'Microelectronics', value: 'Microelectronics' },
				{ text: 'Security Technology', value: 'Security Technology' },
				{ text: 'Web Applications', value: 'Web Applications' },
			],
			optionsBusiness_services: [
				{ text: 'Accounting', value: 'Accounting' },
				{ text: 'Banking Services', value: 'Banking Services' },
				{ text: 'Beauty & Wellness Management', value: 'Beauty & Wellness Management' },
				{ text: 'Early Childhood Education', value: 'Early Childhood Education' },
				{ text: 'Event Management', value: 'Event Management' },
				{ text: 'Human Resource & Administration', value: 'Human Resource & Administration' },
				{ text: 'International Logistics', value: 'International Logistics' },
				{ text: 'Leisure & Travel Operations', value: 'Leisure & Travel Operations' },
				{ text: 'Maritime Business', value: 'Maritime Business' },
				{ text: 'Passenger Services', value: 'Passenger Services' },
				{ text: 'Retail & Online Business', value: 'Retail & Online Business' },
				{ text: 'Service Management', value: 'Service Management' },
				{ text: 'Sport Management', value: 'Sport Management' },
				{ text: 'Beauty & Wellness', value: 'Beauty & Wellness' },
				{ text: 'Business Administration', value: 'Business Administration' },
				{ text: 'Business Services', value: 'Business Services' },
				{ text: 'Financial Services', value: 'Financial Services' },
				{ text: 'Fitness Training', value: 'Fitness Training' },
				{ text: 'Floristry', value: 'Floristry' },
				{ text: 'Hair Fashion & Design', value: 'Hair Fashion & Design' },
				{ text: 'Logistics Services', value: 'Logistics Services' },
				{ text: 'Travel & Tourism Services', value: 'Travel & Tourism Services' },
			],
			optionsDesign_media: [
				{ text: 'Architectural Technology', value: 'Architectural Technology' },
				{ text: 'Filmmaking (Cinematography)', value: 'Filmmaking (Cinematography)' },
				{ text: 'Interactive Design', value: 'Interactive Design' },
				{ text: 'Motion Graphics', value: 'Motion Graphics' },
				{ text: 'Performance Production', value: 'Performance Production' },
				{ text: 'Visual Effects', value: 'Visual Effects' },
				{ text: 'Visual Merchandising', value: 'Visual Merchandising' },
				{ text: 'Digital Animation', value: 'Digital Animation' },
				{ text: 'Fashion Apparel Production & Design', value: 'Fashion Apparel Production & Design' },
				{ text: 'Interior & Exhibition Design', value: 'Interior & Exhibition Design' },
				{ text: 'Product Design', value: 'Product Design' },
				{ text: 'Video Production', value: 'Video Production' },
				{ text: 'Visual Communication', value: 'Visual Communication' },
			],
			optionsHospitality: [
				{ text: 'Culinary Arts', value: 'Culinary Arts' },
				{ text: 'Hospitality Operations', value: 'Hospitality Operations' },
				{ text: 'Pastry & Baking', value: 'Pastry & Baking' },
				{ text: 'Asian Culinary Arts', value: 'Asian Culinary Arts' },
				{ text: 'Western Culinary Arts', value: 'Western Culinary Arts' },
			],
			optionsLife_skill: [
				{ text: 'Decision Making', value: 'Decision Making' },
				{ text: 'Entrepreneurship', value: 'Entrepreneurship' },
				{ text: 'Design Thinking', value: 'Design Thinking' },
				{ text: 'Effective Communications', value: 'Effective Communications' },
				{ text: 'Handling Difficult Customers', value: 'Handling Difficult Customers' },
				{ text: 'Managing Workplace Relationships', value: 'Managing Workplace Relationships' },
				{ text: 'Negotiation Skills', value: 'Negotiation Skills' },
				{ text: 'Presentation Skills', value: 'Presentation Skills' },
				{ text: 'Personal Effectiveness', value: 'Personal Effectiveness' },
				{ text: 'Professional Image and Etiquette', value: 'Professional Image and Etiquette' },
				{ text: 'Team Building', value: 'Team Building' },
				{ text: 'Time Management', value: 'Time Management' },
				{ text: 'Project Management', value: 'Project Management' },
			],
			optionsQuality: [
				{ text: 'Assessment', value: 'Assessment' },
				{ text: 'Training', value: 'Training' },
			],
			optionsTrain: [
				{ text: 'Prepare and Conduct Coaching', value: 'Prepare and Conduct Coaching' },
				{ text: 'Deliver and Facilitate Learning', value: 'Deliver and Facilitate Learning' },
				{ text: 'Design and Develop On-the-Job-Training (OJT) Programme', value: 'Design and Develop On-the-Job-Training (OJT) Programme' },
				{ text: 'Develop and Conduct Assessment', value: 'Develop and Conduct Assessment' },
				{ text: 'Design and Develop Training Curriculum (DACUM)', value: 'Design and Develop Training Curriculum (DACUM)' },
				{ text: 'Continuing Education and Training (CET) System', value: 'Continuing Education and Training (CET) System' },
				{ text: 'Structured Internship Framework', value: 'Structured Internship Framework' },
			],
			optionsPedadogical: [
				{ text: 'Design & Facilitate Collaborative Student-Centred Learning', value: 'Design & Facilitate Collaborative Student-Centred Learning' },
				{ text: 'Develop Professional Competence for the Workplace', value: 'Develop Professional Competence for the Workplace' },
				{ text: 'Design & Facilitate Engaging Frontal Delivery', value: 'Design & Facilitate Engaging Frontal Delivery' },
				{ text: 'Design & Develop Effective Lesson Plans and Demonstration Plans', value: 'Design & Develop Effective Lesson Plans and Demonstration Plans' },
				{ text: 'Organise Specific Learning Outcomes (SLO) for a Job Role', value: 'Organise Specific Learning Outcomes (SLO) for a Job Role' },
				{ text: 'Use Demonstration Effectively as a Method of Training Delivery', value: 'Use Demonstration Effectively as a Method of Training Delivery' },
				{ text: 'Develop Effective Multimedia Instructional Presentation', value: 'Develop Effective Multimedia Instructional Presentation' },
				{ text: 'Manage & Motivate Students For Effective Classroom Learning', value: 'Manage & Motivate Students For Effective Classroom Learning' },
				{ text: 'Deliver & Facilitate Learning', value: 'Deliver & Facilitate Learning' },
				{ text: 'Develop Assessment of Learning', value: 'Develop Assessment of Learning' },
				{ text: 'Promote Reflective Self & Peer Assessment', value: 'Promote Reflective Self & Peer Assessment' },
				{ text: 'Design & Develop Competence Based Instructional Plan', value: 'Design & Develop Competence Based Instructional Plan' },
				{ text: 'Design Student-Centred Learning Activities', value: 'Design Student-Centred Learning Activities' },
				{ text: 'Develop Print-Based Learner’s Guide & Learning Documents', value: 'Develop Print-Based Learner’s Guide & Learning Documents' },
				{ text: 'Promote Reflective Learning through Video-Based Learning Resources', value: 'Promote Reflective Learning through Video-Based Learning Resources' },
				{ text: 'Design ILL-Defined Learning Tasks For Engaged Learning', value: 'Design ILL-Defined Learning Tasks For Engaged Learning' },
				{ text: 'Design & Develop Digital Teaching and Learning Resources', value: 'Design & Develop Digital Teaching and Learning Resources' },
				{ text: 'Developing Learner Engagement Plan (LEP)', value: 'Developing Learner Engagement Plan (LEP)' },
				{ text: 'Design Job-Role-Centered Learning', value: 'Design Job-Role-Centered Learning' },
				{ text: 'Develop Effective Lesson Plans & Demonstration Plans for Skills Acquisition', value: 'Develop Effective Lesson Plans & Demonstration Plans for Skills Acquisition' },
			],
			optionsLeader: [
				{ text: 'Role of TVET in Singapore’s Economic Development', value: 'Role of TVET in Singapore’s Economic Development' },
				{ text: 'Role of TVET in Singapore’s Social Development', value: 'Role of TVET in Singapore’s Social Development' },
				{ text: 'Building a Responsive and Resilient TVET amidst Covid-19', value: 'Building a Responsive and Resilient TVET amidst Covid-19' },
				{ text: 'Developing a Successful TVET System – the Singapore Experience', value: 'Developing a Successful TVET System – the Singapore Experience' },
				{ text: 'Building a Sustainable TVET System', value: 'Building a Sustainable TVET System' },
				{ text: 'Developing a Competitive World Class Workforce', value: 'Developing a Competitive World Class Workforce' },
				{ text: 'Transforming TVET, Transforming Lives', value: 'Transforming TVET, Transforming Lives' },
				{ text: 'The ITE Journey of Excellence Outside In and Inside Out', value: 'The ITE Journey of Excellence Outside In and Inside Out' },
				{ text: 'Transforming Education in the 21st century ', value: 'Transforming Education in the 21st century ' },
				{ text: 'Benefits and Challenges of New Technology Developments to Governments, Industries and the Workforce', value: 'Benefits and Challenges of New Technology Developments to Governments, Industries and the Workforce' },
				{ text: 'TVET Learning Environment Innovation', value: 'TVET Learning Environment Innovation' },
				{ text: 'TVET Campus Design and Development', value: 'TVET Campus Design and Development' },
				{ text: 'TVET Partnership with Industry, for industry', value: 'TVET Partnership with Industry, for industry' },
				{ text: 'TVET Branding and Marketing', value: 'TVET Branding and Marketing' },
				{ text: 'Smart Teaching to Prepare Students for the Digital World', value: 'Smart Teaching to Prepare Students for the Digital World' },
				{ text: 'Design Thinking for TVET Transformation', value: 'Design Thinking for TVET Transformation' },
				{ text: 'Management of a TVET College', value: 'Management of a TVET College' },
				{ text: 'TVET Academic Planning', value: 'TVET Academic Planning' },
				{ text: 'Quality TVET Curriculum', value: 'Quality TVET Curriculum' },
				{ text: 'Quality TVET Staff', value: 'Quality TVET Staff' },
				{ text: 'Quality TVET Assessment', value: 'Quality TVET Assessment' },
				{ text: 'Quality TVET Teacher Development', value: 'Quality TVET Teacher Development' },
			],
			optionsTech: [
				{ text: 'Industry 4.0', value: 'Industry 4.0' },
				{ text: 'Augmented Reality (AR) / Virtual Reality (VR) / Mixed Reality (MR)', value: 'Augmented Reality (AR) / Virtual Reality (VR) / Mixed Reality (MR)' },
				{ text: 'Artificial Intelligence', value: 'Artificial Intelligence' },
				{ text: 'e-Learning / Virtual Classroom', value: 'e-Learning / Virtual Classroom' },
			],
			selectedAnnexA: {
				text: 'Current - Required',
				value: 1
			},
			optionsAnnexA: [
				{ text: 'Current - Required', value: 1 },
				{ text: 'Current - Past', value: 2 },
			],
			dataSelect: {
				"andex_current": { text: 'Current - Required', value: 1 },
				"andex_past": { text: 'Current - Past', value: 2 },
			},
		}
	},
	props: {
		permission: {
			type: [Array, String, Number, Object],
		}
	},
	created() {
		this.detailItem()
	},
	methods: {
		toEdit(id) {
			this.$router.push(`/admin/staff-management/TVET-experts/edit/${id}`)
		},
		length100(e) {
			return String(e).substring(0, 100)
		},
		editAction() {
			this.isDisabled = false
			this.editing = true
			this.isEdit = true
		},
		cancelEdit() {
			this.isDisabled = true
			this.editing = false
			this.isEdit = false
		},
		detailItem() {
			if(!this.permission.read){
				return;
			}
			this.$store
				.dispatch('project/detailTVETExpert', {
					id: this.$route.params.id
				})
				.then(response => {
					this.optionsAnnexA = []
					response.data.data.andexA.forEach((value, index) => {
						this.optionsAnnexA.push({ text: this.dataSelect[`${value}`].text, value: this.dataSelect[`${value}`].value });
					});
					this.detail = response.data.data
					this.step2 = response.data.data.andex_current
					this.step3 = response.data.data.andex_past

				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
		saveEditStep2() {
			let datas = {}
			if (this.selectedAnnexA && this.selectedAnnexA.value == 1) {
				if (this.step2 && this.step2.cb_applied_health.length == 0 &&
					this.step2 && this.step2.cb_engineering.length == 0 &&
					this.step2 && this.step2.cb_tech_electronics.length == 0 &&
					this.step2 && this.step2.cb_business_services.length == 0 &&
					this.step2 && this.step2.cb_design_media.length == 0 &&
					this.step2 && this.step2.cb_hospitality.length == 0 &&
					this.step2 && this.step2.cb_life_skill.length == 0 &&
					this.step2 && this.step2.cb_quality.length == 0 &&
					this.step2 && this.step2.cb_train.length == 0 &&
					this.step2 && this.step2.cb_pedadogical.length == 0 &&
					this.step2 && this.step2.cb_leader.length == 0 &&
					this.step2 && this.step2.cb_tech.length == 0) {

					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Annex A current is required',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				}
				else {
					datas = {
						page_name: 'andex_current',
						cb_applied_health: this.step2.cb_applied_health,
						cb_engineering: this.step2.cb_engineering,
						cb_tech_electronics: this.step2.cb_tech_electronics,
						cb_business_services: this.step2.cb_business_services,
						cb_design_media: this.step2.cb_design_media,
						cb_hospitality: this.step2.cb_hospitality,
						cb_quality: this.step2.cb_quality,
						cb_train: this.step2.cb_train,
						cb_pedadogical: this.step2.cb_pedadogical,
						cb_leader: this.step2.cb_leader,
						cb_tech: this.step2.cb_tech,
						cb_life_skill: this.step2.cb_life_skill,
						textarea_other: this.step2.textarea_other,
					}
					this.$store

						.dispatch('project/editTVETExpert',
							{
								id: this.$route.params.id,
								data: datas
							})
						.then(response => {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: this.detail.eoi_name + ' Updated!',
									variant: 'success',
								},
							},
								{
									position: 'top-center'
								}
							)
							this.detailItem()
							this.isDisabled = true
							this.isEdit = false
							this.editing = false
						})
						.catch((error) => {
							console.log(error)
							this.$toast({
								component: ToastificationContent,
								props: {
									title: error.response.data.message,
									variant: 'danger',
								},
							},
								{
									position: 'top-center'
								}
							)
						})
				}
			}
			else {
				datas = {
					page_name: 'andex_past',
					cb_applied_health: this.step3.cb_applied_health,
					cb_engineering: this.step3.cb_engineering,
					cb_tech_electronics: this.step3.cb_tech_electronics,
					cb_business_services: this.step3.cb_business_services,
					cb_design_media: this.step3.cb_design_media,
					cb_hospitality: this.step3.cb_hospitality,
					cb_quality: this.step3.cb_quality,
					cb_train: this.step3.cb_train,
					cb_pedadogical: this.step3.cb_pedadogical,
					cb_leader: this.step3.cb_leader,
					cb_tech: this.step3.cb_tech,
					cb_life_skill: this.step3.cb_life_skill,
					textarea_other: this.step3.textarea_other,
				}
				this.$store
					.dispatch('project/editTVETExpert',
						{
							id: this.$route.params.id,
							data: datas
						})
					.then(response => {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: this.detail.eoi_name + ' Updated!',
								variant: 'success',
							},
						},
							{
								position: 'top-center'
							}
						)
						this.detailItem()
						this.isDisabled = true
						this.isEdit = false
						this.editing = false
					})
					.catch((error) => {
						console.log(error)
						this.$toast({
							component: ToastificationContent,
							props: {
								title: error.response.data.message,
								variant: 'danger',
							},
						},
							{
								position: 'top-center'
							}
						)
					})
			}
		},
	}
}
</script>