<template>
	<section>
		<b-card class="px-table-0 mt-1 w-10-percent-cl-1 w-15-percent-cl-2 w-20-percent-cl-3 w-20-percent-cl-4">
		    <b-table small :fields="fields_items" :items="items" tbody-tr-class="size-14 table-row" show-empty @head-clicked="headClicked">
		    	<template #head(id)="data">
		    		<span>{{ data.label }}</span>
		    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 20px;">
			        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
			        </span>
		    	</template>
		    	<template #head()="data">
		    		<span class="d-block text-center">{{ data.label }}</span>
		    	</template>
		    	<template #head(activity_name)="data">
		    		<span>{{ data.label }}</span>
		    	</template>
		    	<template #cell(activity_description)="data">
		    		<span class="maxThreeRow text-center">
		    			<template v-if="data.item.activity_description">{{ data.item.activity_description }}</template>
		    			<template v-else>-</template>
		    		</span>
		    	</template>	
		    	<template #cell(activity_name)="data">
		    		<span class="maxTwoRow">{{ data.item.activity_name }}</span>
		    	</template>	
		    	<template #cell(id)="data">					
					<span v-if="data.field.key == sort.field && sort.type == 'desc'">
						<template v-if="selected == 10">
							{{ ((total - data.index) - ((currentPage - 1) * 10 )) }}
						</template>
						<template v-else-if="selected == 20">
							{{ ((total - data.index) - ((currentPage - 1) * 20 )) }}
						</template>
						<template v-else>
							{{ ((total - data.index) - ((currentPage - 1) * 30 )) }}
						</template>
					</span>
					<span v-else>
						<template v-if="selected == 10">
							{{ ((currentPage - 1) * 10) + (data.index + 1) }}
						</template>
						<template v-else-if="selected == 20">
							{{ ((currentPage - 1) * 20) + (data.index + 1) }}
						</template>
						<template v-else>
							{{ ((currentPage - 1) * 30) + (data.index + 1) }}
						</template>
					</span>
				</template>	
				<template #cell(action)="data">
					<div class="d-block text-center">
						<span class="px-05 cursor-pointer" @click.stop="clickActivity(data.item.country_id, data.item.project_id, data.item.activity_id)">
							<feather-icon
					            icon="EyeIcon"
					            size="22"
					            color="#8471FF"
					          />
						</span>
					</div>										
				</template>		
				<template #cell(location)="data">
					<span class="d-block text-center">
						<template v-if="data.item.location == 0">Online</template>
						<template v-if="data.item.location == 1">Face to face</template>
					</span>
				</template>	
				<template #cell(activity_status)="data">
					<span class="d-block text-center">
						<template v-if="data.item.activity_status == 0">Not Done</template>
						<template v-if="data.item.activity_status == 1">In Progress</template>
						<template v-if="data.item.activity_status == 2">Done</template>
						<template v-if="data.item.activity_status == 3">Terminated</template>
					</span>
				</template>	
				<template #cell()="data">
					<span class="d-block text-center">{{ data.value }}</span>
				</template>				
				<template #empty="scope">
			      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
			    </template>
			</b-table>
		</b-card>
		<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="active-table"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		    <div class="limit-per">
				<select v-model="selected" class="cursor-pointer">
				    <option v-for="limit in limitpage">{{ limit }}</option>
				</select>			  
		    </div>
		</div>
	</section>
</template>
<script>
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		data(){
			return{
	            sort: {
			    	field: 'id',
			    	type: 'asc'
			    },

    			fields_items: [
                	{ key: 'id', label: 'No.' },
			        { key: 'activity_name', label: 'Activity Listing'},
			        { key: 'project_name', label: 'Project Name'},
			        { key: 'activity_description', label: 'Description'},
			        { key: 'location', label: 'Location'},
			        { key: 'activity_status', label: 'Activity Status'},
			    	{ key: 'action', label: 'Actions'},
			    ],
		    	items: [],	
				perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',
			}
		},
		mounted () {
		    this.selected = '10'
		},
		created(){
			this.featctList()
		},
		watch: {
			currentPage(val){
				this.featctList()
			},
			selected(val){
			if(val) {
				this.perPage = val
				this.featctList()
			}
        }
		},
		methods: {
			featctList(){
				this.$store
				.dispatch('project/listExpertsAssign', {
					filter: {
					  "expert_id": this.$route.params.id
					},
					limit: this.perPage,
					page: this.currentPage,
					sort: this.sort,
				})
				.then(response => {
					this.items = response.data.data.data
					this.total = response.data.data.total
					// console.log('list activity: ', response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			headClicked(key) {
				if(this.sort.field == key) {
					if(this.sort.type == 'asc') this.sort.type = 'desc'
					else this.sort.type = 'asc'
				} else {					
					this.sort.field = key
					this.sort.type = 'desc'
				}
				this.featctList()
			},
			clickActivity(country_id, project_id, activity_id){
				this.$router.push({ path: `/admin/our-portfolio/project/${country_id}/edit/${project_id}/activity/${activity_id}` })
			}
		},
	}
</script>
<style lang="scss">
	@import "@/assets/scss/_ite_tab_table.scss";

	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
</style>